<template lang="pug">
    .create-system
      OnboardingWrapper(:title="header.title", :subTitle="header.subTitle", :description="header.description", :stage="header.stage" :maxStages="header.maxStages")
        //-  Redirect
        ConnectButtons(@nav="handleNav")
</template>

<script>
import { call } from "vuex-pathify";
import OnboardingWrapper from "@/components/Onboarding/OnboardingWrapper.vue";
import ConnectButtons from "@/components/Dashboard/Sections/Connections/ConnectButtons.vue";

export default {
  name: "ConnectUnit",
  components: { OnboardingWrapper, ConnectButtons },
  data() {
    return {
      header: {
        title: "Connect your Accounting Software",
        subTitle: " ",
        description:
          "Connect your accounting software company file. Don’t worry, you control access through your accounting software connected Apps page.",
        stage: 2,
        maxStages: 3
      },
      loading: false,
      errors: {}
    };
  },
  methods: {
    ...call({
      setActiveItem: "Onboarding/setActiveItem"
    }),
    handleNav(evt) {
      this.setActiveItem({ to: "ConnectionInfoOne", data: evt.data });
    }
  }
};
</script>
