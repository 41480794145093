<template lang="pug">
    .connections-table(v-show="!loading")
        ModalConfirmDelete(v-if="connectionIdToDelete", :connectionIdToDelete="connectionIdToDelete" @pseudoDelete="wasDeleted.push(connectionIdToDelete)", @done="connectionIdToDelete = null")
                
        .no-connections(v-if="!items || items.length < 1")
          v-card(color="grey lighten-4" flat).pa-5.ma-5.text-left
            p
              b A Connection is a link between Unit Financials and your cloud accounting company file.
            p You have not yet added any Connections.
            p Get started by pressing the "+ Connect New Data Source" button at the top of the page.
        
        .connections(v-else)
          v-data-table.rounded(:headers="headers", :items="items", :hide-default-footer="items.length < 9")
            template(v-slot:item.unitNames="{ item }")
              v-tooltip(right)
                template(v-slot:activator="{ on, attrs }")
                  span(v-on="on") {{item.displayNames}}
                span(v-html="item.unitNames ? item.unitNames.join('<br>') : item.unitCount || '?' + ' units within this connection'")

            template(v-slot:item.nowSyncingStatus="{ item }")
              v-btn(icon small :loading="item.nowSyncingStatus" @click="handleSync({connectionId: item.id, provider: item.provider})")
                v-icon(color="accent" small) mdi-autorenew

            template(v-slot:item.delete="{ item }")
              v-btn(icon small @click="deleteConnection({connectionIdToDelete: item.id})")
                v-icon(color="error" small) mdi-trash-can
            template(v-slot:no-data)
            p You have not yet added any Connections.
            p Get started by pressing the "+ Connect New Data Source" button at the top of the page.
</template>

<script>
import { get, sync, call } from "vuex-pathify";
import { truncateStringBeyond } from "@/helpers.js";

import ModalConfirmDelete from "@/components/Dashboard/Sections/Connections/ModalConfirmDelete.vue";
export default {
  name: "ConnectionsTable",
  components: { ModalConfirmDelete },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Connected By",
          value: "creatorEmail",
          align: "left"
        },
        {
          text: "Connection Name",
          value: "unitNames",
          align: "center"
        },
        {
          text: "Type",
          value: "type",
          align: "center"
        },
        {
          text: "Date Connected",
          value: "createdAt",
          align: "center"
        },
        {
          text: "# of Units",
          value: "unitCount",
          align: "center",
          width: "200"
        },
        {
          text: "Sync Now",
          value: "nowSyncingStatus",
          align: "center"
        },
        {
          text: "",
          value: "delete",
          align: "center"
        }
      ],
      requestedToSyncIds: [],
      connectionIdToDelete: null,
      wasDeleted: []
    };
  },
  watch: {
    loading(newVal) {
      this.$emit("change:loading", newVal);
    },
    syncIds(newVal) {
      if (newVal && newVal.length > 0) {
        newVal.forEach(syncId => {
          const index = this.requestedToSyncIds.findIndex(x => x === syncId);
          if (index > -1) this.requestedToSyncIds.splice(index, 1);
        });
      }
    }
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      myEmail: "User/userData@email",
      connections: "Connections/connections",
      syncIds: "Connections/syncIds",
      prettyPrintTimestamp: "prettyPrintTimestamp",
      prettyPrintProvider: "Connections/prettyPrintProvider"
    }),
    ...sync({
      showModalDeleteConnection: "Nav/modals@showModalDeleteConnection"
    }),
    items() {
      let items = this.connections
        .map(connection => {
          return {
            ...connection,
            createdAt: this.prettyPrintTimestamp(connection.createdAt),
            type: this.prettyPrintProvider(connection.provider),
            creatorEmail:
              connection.creatorEmail === "Me"
                ? "✭ Me"
                : connection.creatorEmail,

            displayNames: !connection.unitNames
              ? ""
              : truncateStringBeyond(
                  connection.unitNames.length == 1
                    ? connection.unitNames[0]
                    : connection.unitNames.join(", "),
                  24
                ),
            nowSyncingStatus:
              this.requestedToSyncIds.includes(connection.id) ||
              this.syncIds.includes(connection.id)
          };
        })
        .filter(connection => !this.wasDeleted.includes(connection.id));
      return items;
    }
  },
  methods: {
    ...call({
      getConnections: "Connections/getConnections",
      bindSyncIds: "Connections/bindSyncIds",
      syncConnection: "Connections/syncConnection"
    }),
    handleSync({ connectionId, provider }) {
      this.syncConnection({ connectionId, provider });
      this.requestedToSyncIds.push(connectionId);
    },
    deleteConnection({ connectionIdToDelete }) {
      this.connectionIdToDelete = connectionIdToDelete;
      this.showModalDeleteConnection = true;
    },
    async init() {
      this.loading = true;
      // set header colors to standard header color
      this.headers.forEach(header => (header.class = "sectionHeaderColor"));
      await this.bindSyncIds();
      await this.getConnections();
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
::v-deep .v-data-table-header {
  th span {
    color: white;
    font-size: 1rem;
    font-weight: 400;
  }
}
</style>
