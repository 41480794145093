<template lang="pug">
    .table-wrapper
        v-data-table.rounded(:loading="loading", :headers="parsedHeaders", :items="items", :hide-default-footer="hideDefaultFooter")
        template(v-if="canExport")
          .ma-3
            v-divider
            vue-json-to-csv(:json-data="exportData", :labels="exportHeaders" :csv-title="exportFilename")
              v-btn(color="primary" outlined).mt-4
                | Export Data
                v-icon.ml-2(small) mdi-download
          
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
export default {
  name: "TableWrapper",
  components: { VueJsonToCsv },
  props: {
    loading: { type: Boolean, default: false },
    canExport: { type: Boolean, default: false },
    headers: { type: Array, required: true },
    items: { type: Array, required: true },
    hideDefaultFooter: { type: Boolean, default: false }
  },
  data() {
    return {};
  },
  computed: {
    exportFilename() {
      const dt = new Date().toISOString().split("T")[0];
      const time = new Date()
        .toISOString()
        .split("T")[1]
        .substr(0, 5)
        .split(":")
        .join("-");

      const timestamp = dt + "__" + time;

      return "UnitFinancials_export_" + timestamp + ".csv";
    },
    parsedHeaders() {
      if (!this.headers || this.headers.length < 1) return [];

      // set header colors to standard header color
      return this.headers.map(header => {
        return { class: "sectionHeaderColor", ...header };
      });
    },
    exportHeaders() {
      if (!this.headers) return null;
      let obj = {};
      this.headers.forEach(header => {
        obj[header.value] = { title: header.text };
      });

      return obj;
    },
    exportData() {
      return this.items;
    }
  }
};
</script>
