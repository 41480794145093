<template lang="pug">
  .basic-details
      v-row
        v-col(cols="12" md="6")
          v-text-field(hide-details :readonly="readOnly" :class="{'readonlyInput': readOnly}" outlined background-color="grey lighten-4" label="First Name" placeholder="Your first name", :value="basicDetails.firstName", @change="handleChange({field: 'firstName', value: $event})")
        v-col(cols="12" md="6")
          v-text-field(hide-details :readonly="readOnly" :class="{'readonlyInput': readOnly}" outlined background-color="grey lighten-4" label="Last Name", placeholder="Your last name", :value="basicDetails.lastName", @change="handleChange({field: 'lastName', value: $event})")
      v-row()
        v-col(cols="12")
          v-text-field(hide-details :readonly="readOnly" :class="{'readonlyInput': readOnly}" outlined background-color="grey lighten-4" label="Email Address" placeholder="your.name@company.com", :value="basicDetails.email", @change="handleChange({field: 'email', value: $event})")
        v-col(cols="12" v-if="showPseudoPassword")
          v-text-field(hide-details readonly disabled outlined background-color="grey lighten-4" label="Password", placeholder="Enter a password", value="••••••••••••")
        v-col(cols="12" v-if="error")
          v-fade-transition
            v-alert(type="error" outlined) {{error}}
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "BasicDetails",
  props: {
    readOnly: { type: Boolean, default: true },
    showPseudoPassword: { type: Boolean, default: false },
    error: { type: String, default: null }
  },
  watch: {
    userData() {
      this.init();
    }
  },
  data() {
    return {
      basicDetails: { firstName: "", lastName: "", email: "" }
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({ userData: "User/userData" })
  },
  methods: {
    handleChange({ field, value }) {
      this.$set(this.basicDetails, field, value);
      this.$emit("change", this.basicDetails);
    },
    init() {
      if (this.userData) {
        const fields = ["firstName", "lastName", "email"];
        fields.forEach(field =>
          this.$set(this.basicDetails, field, this.userData[field])
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.readonlyInput:hover {
  cursor: not-allowed !important;
}
</style>
