<template lang="pug">
.system-report--unit-totals
  template(v-if="reportData && reportData.sumsAndAverages && reportData.sumsAndAverages.averages")
    .unit-averages(v-if="systemProfitAndLossCurrentTab === 'unitAverages'")
      SystemReportForUnitAveragesTopLine(:unitCount="reportData.unitCount", :averageRevenue="reportData.averageRevenue", :averageNetProfit="reportData.averageNetProfit")
      
      v-row.mt-6(justify="center")
        v-col(cols="6")
          SystemAverageLineItems(:averages="reportData.sumsAndAverages.averages", :averageRevenue="reportData.averageRevenue")
    
    .unit-totals(v-if="systemProfitAndLossCurrentTab === 'unitTotals'")
      SystemReportForUnitTotalsTopLine(:unitCount="reportData.unitCount", :totalRevenue="reportData.totalRevenue", :averageRevenue="reportData.averageRevenue", :averageNetProfit="reportData.averageNetProfit")
      v-row.mt-6(justify="center")
        v-col(cols="6")
          SystemTotalsLineItems(:sums="reportData.sumsAndAverages.sums")

</template>
<script>
import SystemReportForUnitAveragesTopLine from "@/components/Dashboard/Sections/Home/Reports/SystemReportForUnitAveragesTopLine.vue";
import SystemReportForUnitTotalsTopLine from "@/components/Dashboard/Sections/Home/Reports/SystemReportForUnitTotalsTopLine.vue";
import SystemAverageLineItems from "@/components/Dashboard/Sections/Home/Reports/SystemAverageLineItems.vue";
import SystemTotalsLineItems from "@/components/Dashboard/Sections/Home/Reports/SystemTotalsLineItems.vue";

import { get } from "vuex-pathify";
export default {
  name: "SystemReportForUnitTotals",
  components: {
    SystemReportForUnitAveragesTopLine,
    SystemReportForUnitTotalsTopLine,
    SystemAverageLineItems,
    SystemTotalsLineItems
  },
  props: {
    systemProfitAndLossCurrentTab: { type: String, required: true }
  },
  computed: {
    ...get({ reportData: "Reports/reports@system" })
  },
  methods: {}
};
</script>
