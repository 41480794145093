<template lang="pug">
    .invite-stat-card
        v-card(outlined, max-width="500px").num-sent.pa-5.font-weight-bold.text-center.mx-auto
            .label {{title}}
            .num {{value}}
            .description(v-html="description")
</template>

<script>
export default {
  name: "InviteStatCard",
  props: {
    title: { type: String, required: true },
    value: { type: Number, required: true },
    description: { type: String, default: "&nbsp;" }
  }
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 1.125rem;
}
.num {
  font-size: 3rem;
}

.description {
  font-size: 1rem;
  font-weight: normal;
}
</style>
