<template lang="pug">
  .work-email-address-input
    v-text-field(autofocus outlined dense :disabled="readOnly" v-model="email", placeholder="mary.smith@company.com" :label="label", :rules="[rules.required, rules.email]", @keydown="handleTyping", @keydown.enter="$emit('submit')")
</template>

<script>
import { sync } from "vuex-pathify";
import { isValidEmail, isValidDomain, splitEmail } from "@/helpers.js";
import debounce from "lodash.debounce";

export default {
  name: "WorkEmailAddress",
  props: {
    label: { type: String, default: "Work Email Address" },
    readOnly: { type: Boolean, default: false }
  },
  data() {
    return {
      isTyping: false,
      typingTimeout: null,
      rules: {
        required: value => !!value || "Please enter a valid email address.",
        email: value => {
          return isValidEmail(value) || "Please enter a valid email address.";
        }
      }
    };
  },
  computed: {
    ...sync({
      domain: "Onboarding/registration@domain",
      email: "Onboarding/registration@email"
    })
  },
  methods: {
    handleTyping() {
      const _this = this;
      this.debounced(_this);
    },
    debounced: debounce(
      _this => _this.handleUpdateEmail(_this, _this.email),
      200
    ),
    handleUpdateEmail(_this, newVal) {
      const split = splitEmail(newVal);
      if (split && split.domain && isValidDomain(split.domain)) {
        _this.domain = split.domain;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
