var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"connections-table"},[(_vm.connectionIdToDelete)?_c('ModalConfirmDelete',{attrs:{"connectionIdToDelete":_vm.connectionIdToDelete},on:{"pseudoDelete":function($event){return _vm.wasDeleted.push(_vm.connectionIdToDelete)},"done":function($event){_vm.connectionIdToDelete = null}}}):_vm._e(),(!_vm.items || _vm.items.length < 1)?_c('div',{staticClass:"no-connections"},[_c('v-card',{staticClass:"pa-5 ma-5 text-left",attrs:{"color":"grey lighten-4","flat":""}},[_c('p',[_c('b',[_vm._v("A Connection is a link between Unit Financials and your cloud accounting company file.")])]),_c('p',[_vm._v("You have not yet added any Connections.")]),_c('p',[_vm._v("Get started by pressing the \"+ Connect New Data Source\" button at the top of the page.")])])],1):_c('div',{staticClass:"connections"},[_c('v-data-table',{staticClass:"rounded",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":_vm.items.length < 9},scopedSlots:_vm._u([{key:"item.unitNames",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.displayNames))])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.unitNames ? item.unitNames.join('<br>') : item.unitCount || '?' + ' units within this connection')}})])]}},{key:"item.nowSyncingStatus",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","loading":item.nowSyncingStatus},on:{"click":function($event){return _vm.handleSync({connectionId: item.id, provider: item.provider})}}},[_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("mdi-autorenew")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteConnection({connectionIdToDelete: item.id})}}},[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-trash-can")])],1)]}},{key:"no-data",fn:function(){return undefined},proxy:true}])},[_c('p',[_vm._v("You have not yet added any Connections.")]),_c('p',[_vm._v("Get started by pressing the \"+ Connect New Data Source\" button at the top of the page.")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }