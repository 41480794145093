<template lang="pug">
  .brand-info
    .d-flex.align-center
      v-text-field(ref="input" outlined dense v-model.trim="brandSpaceLabel", placeholder="ABC Company" label="Brand Space Name", :rules="[rules.required, rules.brandSpaceName]")
    .d-flex.align-center
      v-text-field(ref="input" outlined dense v-model.trim="brandSpaceSubdomain", placeholder="abccompany" persistent-hint hint="" label="Brand Space URL", :rules="[rules.required, rules.brandSpaceSubdomain]", align="right", @keydown.enter="$emit('submit')")
      span.spaced--text.mb-6 .{{hostname}}
</template>

<script>
import { get, sync } from "vuex-pathify";
import {
  isValidBrandSpaceName,
  isValidBrandSpaceSubdomain,
  titleCase
} from "@/helpers.js";

export default {
  name: "SystemNameInput",
  watch: {
    domainWithoutTld() {
      this.initSuggestion();
    }
  },
  data() {
    return {
      rules: {
        required: value => !!value || "",
        brandSpaceName: value => isValidBrandSpaceName(value),
        brandSpaceSubdomain: value => isValidBrandSpaceSubdomain(value)
      }
    };
  },
  created() {
    this.initSuggestion();
  },
  mounted() {
    this.$refs.input.$el.querySelector("input").classList.add("text-right");
  },
  computed: {
    ...get({ hostname: "hostname", domain: "Onboarding/registration@domain" }),
    ...sync({
      brandSpaceSubdomain: "Onboarding/registration@brandSpaceSubdomain",
      brandSpaceLabel: "Onboarding/registration@brandSpaceLabel"
    }),
    domainWithoutTld() {
      if (!this.domain || !this.domain.includes(".")) return "";
      return this.domain.trim().split(".")[0];
    }
  },
  methods: {
    initSuggestion() {
      this.brandSpaceLabel = titleCase(this.domainWithoutTld);
      this.brandSpaceSubdomain = this.domainWithoutTld.toLowerCase();
    }
  }
};
</script>

<style lang="scss" scoped>
.spaced--text {
  letter-spacing: 1px;
}
</style>
