// import router from '@/router'

import Firebase from "firebase/app";
import { make } from "vuex-pathify";

import { db } from "@/firebase.js";

import CreateSystem from "@/components/Onboarding/System/CreateSystem.vue";
import CreateSystemAccount from "@/components/Onboarding/System/CreateSystemAccount.vue";
import CreateSystemInvitations from "@/components/Onboarding/System/CreateSystemInvitations.vue";

import InviteeJoin from "@/components/Onboarding/Invitees/InviteeJoin.vue";
import ConnectUnit from "@/components/Onboarding/Invitees/Connect.vue";
import ConnectionInfoOne from "@/components/Onboarding/Invitees/ConnectionInfoOne.vue";
import ConnectionInfoTwo from "@/components/Onboarding/Invitees/ConnectionInfoTwo.vue";
import ConnectionComplete from "@/components/Onboarding/Invitees/ConnectionComplete.vue";

const state = {
  components: [
    CreateSystem,
    CreateSystemAccount,
    CreateSystemInvitations,

    InviteeJoin,
    ConnectUnit,
    ConnectionInfoOne,
    ConnectionInfoTwo,
    ConnectionComplete
  ],
  activeComponent: CreateSystem,
  activeComponentProps: {},
  registration: {
    conceptId: null,
    brandSpaceSubdomain: null,
    brandSpaceLabel: null,
    domain: null,
    email: null,
    firstName: null,
    lastName: null,
    inviteEmailAddresses: ["", "", ""],
    createdBy: null, //(does not apply to system creators)
    inviteeClickedToOpenEmail: false,
    inviteeCompletedOnboarding: false
  }
};
const getters = {
  ...make.getters(state)
};

const mutations = make.mutations(state);

const actions = {
  setActiveItem: ({ getters, commit }, { to, data }) => {
    if (to && to.length > 0) {
      const component = getters.components.find(
        component => component.name === to
      );
      if (component) {
        commit("SET_ACTIVE_COMPONENT", component);
        commit("SET_ACTIVE_COMPONENT_PROPS", data);
      } else {
        console.error("Could not find component called", to);
      }
    }
  },

  setStage: ({ getters, commit }, { newStage }) => {
    let componentName = "";
    switch (getters.activeComponent.name) {
      case "CreateSystem":
        //first step, can't move behind
        break;
      case "CreateSystemAccount":
        if (newStage == 0) componentName = "CreateSystem";
        break;
      case "CreateSystemInvitations":
        if (newStage == 0) componentName = "InviteeJoin";
        if (newStage == 1) componentName = "CreateSystemAccount";
        break;

      case "InviteeJoin":
        //first step, can't move behind
        break;
      case "ConnectUnit":
        if (newStage == 0) componentName = "InviteeJoin";
        break;

      case "ConnectionInfoOne":
        //first step, can't move behind
        break;
      case "ConnectionInfoTwo":
        if (newStage == 0) componentName = "ConnectionInfoOne";
        break;
      case "ConnectionComplete":
        if (newStage == 0) componentName = "ConnectionInfoOne";
        if (newStage == 1) componentName = "ConnectionInfoTwo";
        break;
    }
    if (componentName)
      actions.setActiveItem({ getters, commit }, { to: componentName });
  },

  initUser: async ({ getters, commit }) => {
    if (Firebase.auth().currentUser && Firebase.auth().currentUser.uid) {
      const user = await (
        await db
          .collection("users")
          .doc(Firebase.auth().currentUser.uid)
          .get()
      ).data();

      commit("SET_REGISTRATION", {
        ...getters.registration,
        conceptId: user.conceptId
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
