<template lang="pug">
    v-btn(color="primary", outlined, :disabled="disabled", :loading="loading", depressed, block large @click="$emit('click')") 
        v-icon.ml-2(small v-if="icon") {{icon}}
        | {{text}}
         
</template>

<script>
export default {
  name: "BtnPrevious",
  props: {
    text: { type: String, default: "Previous" },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    icon: { type: String, default: "mdi-arrow-left" }
  }
};
</script>
