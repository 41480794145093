<template lang="pug">
    .tab-selector
        v-tabs(:value="items.findIndex(itm=>itm.value === value)")
            v-tab(v-for="tab in items", :key="tab.value" @click="$emit('click', tab.value)") {{tab.text}}
</template>

<script>
export default {
  name: "TabSelector",
  props: {
    items: { type: Array, required: true },
    value: { type: String, required: true }
  }
};
</script>

<style scoped>
::v-deep .v-tab {
  text-transform: none;
}
::v-deep .v-tab--active {
  font-weight: bold;
}
</style>
