<template lang="pug">
    .unit-by-unit-report
      template(v-if='userViewableConnections && userViewableConnections.length > 0')
          v-row(dense justify="center" align="center")
            v-col(cols="1" v-if="userViewableConnections.length > 1")
              v-btn(icon :disabled="activeConnectionIndex <= 0" @click="goToPrevConnection")
                v-icon mdi-arrow-left-circle
            template(v-if="activeReport && activeReport.totalIncome")
              v-col(cols="10")
                .unit-details
                  UnitByUnitReportTopLine(:unitCount="activeConnection.unitCount", :revenue="activeReport.totalIncome", :netProfitPercent="activeReportNetProfitPercent")
                  .as-chart(v-if="unitProfitAndLossCurrentTab === 'chart'")
                    UnitReportChart(:key="activeConnection.id", :unitName="activeUnitName" :unitReportData="activeReport")
                  .as-numbers(v-if="unitProfitAndLossCurrentTab === 'details'")
                    v-row.mt-12(dense justify="center")
                      v-col(cols="12" md="3")
                        UnitDrillDownItems(:headerItem="{name: 'Revenue', data: activeReport.totalIncome}", :topItems="activeReport.totalIncome.topItems" )
                      v-col(cols="12" md="1").text-center
                        v-divider(vertical)
                      v-col(cols="12" md="3")
                        UnitDrillDownItems(:headerItem="{name: 'COGS', data: activeReport.cogs}", :topItems="activeReport.cogs.topItems" )
                      v-col(cols="12" md="1").text-center
                        v-divider(vertical)
                      v-col(cols="12" md="3")
                        UnitDrillDownItems(:headerItem="{name: 'Expenses', data: activeReport.expenses}", :topItems="activeReport.expenses.topItems" )
            template(v-else)
              v-col(cols="10")
                v-skeleton-loader(type="card")
            v-col(cols="1" v-if="userViewableConnections.length > 1")
              v-btn(icon :disabled="activeConnectionIndex >= connections.length - 1" @click="goToNextConnection")
                v-icon mdi-arrow-right-circle
        
      template(v-else)
        v-skeleton-loader(type="image")
</template>
<script>
import { zeroDivideFloat } from "@/helpers";
import UnitReportChart from "@/components/Dashboard/Sections/Home/Reports/UnitReportChart.vue";
import UnitDrillDownItems from "@/components/Dashboard/Sections/Home/Reports/UnitDrillDownItems.vue";
import UnitByUnitReportTopLine from "@/components/Dashboard/Sections/Home/Reports/UnitByUnitReportTopLine.vue";
import { get, call } from "vuex-pathify";
export default {
  name: "UnitByUnitReport",
  components: {
    UnitDrillDownItems,
    UnitReportChart,
    UnitByUnitReportTopLine
  },
  watch: {
    startDate() {
      this.init();
    },
    endDate() {
      this.init();
    },
    activeConnection(newVal) {
      let unitName = "Unit";
      if (!newVal) {
        unitName = "Unit";
      } else if (newVal.unitNames) {
        unitName = newVal.unitNames.join(", ");
      } else {
        unitName = newVal.provider.toUpperCase() + " Connection";
      }

      this.$emit("change:unitName", unitName);
    }
  },
  props: {
    unitProfitAndLossCurrentTab: { type: String, required: true }
  },
  data() {
    return {
      activeConnectionIndex: 0
    };
  },
  computed: {
    ...get({
      connections: "Connections/connections",
      unitByUnitReports: "Reports/reports@unitByUnit",
      startDate: "Reports/startDate",
      endDate: "Reports/endDate"
    }),
    userViewableConnections() {
      //server data retrieval is by userId so only viewable connections are returned
      return this.connections;
    },
    hasUserViewableConnections() {
      return (
        this.unitByUnitReports &&
        this.userViewableConnections &&
        this.userViewableConnections.length > 0
      );
    },
    activeUnitName() {
      let unitName = "Unit";
      if (!this.activeConnection) {
        unitName = "Unit";
      } else if (this.activeConnection.unitNames) {
        unitName = this.activeConnection.unitNames.join(", ");
      } else {
        unitName = this.activeConnection.provider.toUpperCase() + " Connection";
      }
      return unitName;
    },
    activeConnection() {
      if (!this.hasUserViewableConnections || this.activeConnectionIndex < 0)
        return {};
      else return this.connections[this.activeConnectionIndex];
    },
    activeReport() {
      if (!this.hasUserViewableConnections || this.activeConnectionIndex < 0)
        return {};
      else return this.unitByUnitReports[this.activeConnectionIndex];
    },
    activeReportNetProfitPercent() {
      if (!this.activeReport) return "-";

      return (
        zeroDivideFloat(
          this.activeReport.netIncome.value,
          this.activeReport.totalIncome.value,
          0
        ) * 100
      ).toFixed(1);
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...call({
      getUnitByUnitParsedReportData: "Reports/getUnitByUnitParsedReportData"
    }),
    goToNextConnection() {
      if (
        !this.userViewableConnections ||
        this.userViewableConnections.length < 1
      ) {
        this.activeConnectionIndex = 0;
      } else if (
        this.userViewableConnections.length >
        this.activeConnectionIndex + 1
      ) {
        this.activeConnectionIndex++;
      } else {
        this.activeConnectionIndex = 0;
      }
    },
    goToPrevConnection() {
      if (
        !this.userViewableConnections ||
        this.userViewableConnections.length < 1
      ) {
        this.activeConnectionIndex = 0;
      } else if (this.activeConnectionIndex === 0) {
        this.activeConnectionIndex = this.userViewableConnections.length - 1;
      } else {
        this.activeConnectionIndex--;
      }
    },
    async init() {
      await this.getUnitByUnitParsedReportData();
      this.activeConnectionIndex = this.hasUserViewableConnections ? 0 : -1;
    }
  }
};
</script>
