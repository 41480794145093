<template lang="pug">
    .manage-users
        DashboardSectionWrapper(:subtitle="$options.subtitle" :loading="loading")
        UsersTable(@change:loading="loading = $event")

</template>

<script>
import DashboardSectionWrapper from "@/components/Dashboard/Sections/DashboardSectionWrapper.vue";
import UsersTable from "@/components/Dashboard/Sections/Users/UsersTable.vue";
export default {
  name: "ManageUsers",
  title: "Manage Users",
  components: {
    DashboardSectionWrapper,
    UsersTable
  },
  data() {
    return { loading: true };
  }
};
</script>
