<template lang="pug">
    v-btn(color="primary", class="primary-extra"  :disabled="disabled", :loading="loading", depressed, block large @click="$emit('click')") 
        | {{text}}
        v-icon.ml-2(small v-if="icon") {{icon}}
</template>

<script>
export default {
  name: "BtnNext",
  props: {
    text: { type: String, default: "Next" },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    icon: { type: String, default: "mdi-arrow-right" }
  }
};
</script>
