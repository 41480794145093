<template lang="pug">
    .create-system
      OnboardingWrapper(:title="header.title", :subTitle="header.subTitle", :description="header.description", :stage="header.stage" :maxStages="header.maxStages")
        .box(:class="{'grey lighten-3': showingScroller, 'pa-3': showingScroller}")
          v-virtual-scroll(:height="scrollerMaxHeight" :item-height="scrollerItemHeight", :items="inviteEmailAddresses", :bench="inviteEmailAddresses.length")
            template(v-slot:default="{ item, index }")
              InviteeEmailAddress(:key="index", label="Invitee Email Address").mt-2
        BtnAddInvitee

        BtnNext(text="Send Invites", icon="" :loading="loading" @click="handleClick").mt-4

        .errors.mt-2
          .error-alert.no-emails-entered.red--text.mb-2(v-if="errors.noEmailEntered.active") {{errors.noEmailEntered.text}}
          .error-alert.no-emails-entered.red--text.mb-2(v-if="errors.inviteeEmailIsSameAsAdmin.active") {{errors.inviteeEmailIsSameAsAdmin.text}}
</template>

<script>
import Firebase from "firebase/app";
import { get, call } from "vuex-pathify";

import OnboardingWrapper from "@/components/Onboarding/OnboardingWrapper.vue";
import BtnNext from "@/components/Global/BtnNext.vue";
import InviteeEmailAddress from "@/components/Onboarding/System/InviteeEmailAddress.vue";
import BtnAddInvitee from "@/components/Onboarding/System/BtnAddInvitee.vue";

import { isValidEmail } from "@/helpers.js";

export default {
  name: "CreateSystemInvitations",
  components: {
    OnboardingWrapper,
    BtnNext,
    InviteeEmailAddress,
    BtnAddInvitee
  },
  data() {
    return {
      loading: false,
      header: {
        title: "Invite Units to Connect",
        subTitle: " ",
        description:
          "Enter unit owner email addresses below to invite them to join and contribute financial data. Add more any time in the Invites section of your Brand Space.",
        stage: 3,
        maxStages: 3
      },
      scrollerItemHeight: 50,
      errors: {
        noEmailEntered: {
          text:
            "Not ready to share with unit owners?  Try out with a colleague or personal email.",
          active: false
        },
        inviteeEmailIsSameAsAdmin: {
          text: "You cannot send an invite to yourself.",
          active: false
        }
      }
    };
  },
  computed: {
    ...get({
      inviteEmailAddresses: "Onboarding/registration@inviteEmailAddresses"
    }),
    myEmail() {
      return Firebase.auth().currentUser.email;
    },
    showingScroller() {
      return this.inviteEmailAddresses.length > 5;
    },
    scrollerMaxHeight() {
      return this.showingScroller
        ? 200
        : this.inviteEmailAddresses.length * this.scrollerItemHeight +
            this.inviteEmailAddresses.length;
    },
    someEmailAddressesHaveBeenEntered() {
      return this.inviteEmailAddresses.some(
        email => email && email.length > 0 && isValidEmail(email)
      );
    },
    inviteeEmailIsSameAsAdmin() {
      if (!this.myEmail) return false;

      const found = this.inviteEmailAddresses.find(
        inviteeEmail =>
          inviteeEmail.toLowerCase().trim() ===
          this.myEmail.toLowerCase().trim()
      );

      return !!found;
    }
  },
  methods: {
    ...call({
      setActiveItem: "Onboarding/setActiveItem",
      sendInvites: "Invitations/sendInvites"
    }),
    handleClick() {
      this.loading = true;
      this.errors.noEmailEntered.active = false;
      this.errors.inviteeEmailIsSameAsAdmin.active = false;

      if (this.inviteeEmailIsSameAsAdmin) {
        this.errors.inviteeEmailIsSameAsAdmin.active = true;
        this.loading = false;
        return;
      }

      if (this.someEmailAddressesHaveBeenEntered) {
        this.sendInvites();
        this.$router.push("/dashboard/inviteList");
      } else {
        this.errors.noEmailEntered.active = true;
      }
      this.loading = false;
    }
  }
};
</script>
