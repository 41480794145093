<template lang="pug">
    .create-system
      OnboardingWrapper(:title="header.title", :subTitle="header.subTitle", :description="header.description", :stage="header.stage" :maxStages="header.maxStages")
        v-form(ref="form" v-model.trim="isFormValid")
          v-row()
            v-col(cols="12")
              v-text-field(dense :value="brandSpaceLabel", label="Brand Space Name" readonly, disabled, outlined, hide-details)
            v-col.mb-6(cols="12")
              v-text-field(dense :value="brandUrl", label="Brand Space URL" readonly, disabled, outlined, hide-details)
          v-row()
            v-col(cols="12")
              v-text-field(dense :value="email", readonly, disabled, outlined placeholder="mary.smith@example.com" label="Work Email Address", :rules="[rules.required, rules.email]", hide-details)
            v-col(cols="6")
              v-text-field(autofocus dense v-model.trim="firstName", outlined placeholder="Mary" label="First Name", :rules="[rules.required]", hide-details)
            v-col(cols="6")
              v-text-field(dense v-model.trim="lastName", outlined placeholder="Smith" label="Last Name", :rules="[rules.required]", hide-details)
            v-col(cols="12")
              v-text-field#pasword(dense v-model="password", :type="showPasswordInput ? 'password' : 'text'", label="Create a Password" persistent :messages="inputMessages.password", outlined, :rules="[rules.required, rules.password]", :append-icon="showPasswordInput ? 'mdi-eye' : 'mdi-eye-off'", @click:append="() => (showPasswordInput = !showPasswordInput)", @keydown.enter="handleClick")
            

            v-col(cols="12")
              BtnNext(:disabled="disableNext" :loading="loading", @click="handleClick")
                  
              v-fade-transition
                v-alert(v-if="showWarning" color="warning" outlined border="left" small).mt-4 Be sure to enter your name and create a password
                v-alert(v-if="errors.errorCreatingUser" color="error" outlined border="left" small).mt-4.pa-3 There was a problem creating this user. Are you sure this is the correct email address, and that you have never registered it before?
                  v-btn.mt-2.ml-4(outlined color="error" @click="setActiveItem({to: 'CreateSystem'})") Try again
</template>

<script>
import OnboardingWrapper from "@/components/Onboarding/OnboardingWrapper.vue";
import BtnNext from "@/components/Global/BtnNext.vue";
import { get, sync, call } from "vuex-pathify";
import { isValidPassword, isValidEmail } from "@/helpers.js";

export default {
  name: "CreateSystemAccount",
  components: { OnboardingWrapper, BtnNext },
  data() {
    return {
      header: {
        title: "Create Your Account", //intentionally repeated on each page, in case the title needs to be customized
        subTitle: "",
        description: "Create your admin account to manage your Brand Space.",
        stage: 2,
        maxStages: 3
      },
      showWarning: false,
      showPasswordInput: true,
      password: null,
      loading: false,
      rules: {
        required: value => !!value || "Required.",
        password: value => {
          return (
            isValidPassword(value) ||
            "Your password should contain a number and be at least 8 characters long"
          );
        },
        email: value => {
          return isValidEmail(value) || "Please enter a valid work e-mail.";
        }
      },
      isFormValid: false,
      errors: {
        errorCreatingUser: false
      },
      inputMessages: {
        password: "At least 8 characters with at least one number"
      }
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      brandSpaceLabel: "Onboarding/registration@brandSpaceLabel",
      hostname: "hostname"
    }),
    ...sync({
      brandSpaceSubdomain: "Onboarding/registration@brandSpaceSubdomain",
      email: "Onboarding/registration@email",
      firstName: "Onboarding/registration@firstName",
      lastName: "Onboarding/registration@lastName"
    }),
    brandUrl() {
      return !this.brandSpaceSubdomain
        ? ""
        : this.brandSpaceSubdomain.toLowerCase() + "." + this.hostname;
    },
    disableNext() {
      return (
        !this.isFormValid ||
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.brandSpaceSubdomain ||
        !this.brandSpaceLabel
      );
    }
  },
  methods: {
    ...call({
      register: "User/register",
      registerBrandSpace: "System/registerBrandSpace",
      setActiveItem: "Onboarding/setActiveItem"
    }),
    async handleClick() {
      this.loading = true;
      this.showWarning = false;
      this.errors.errorCreatingUser = false;
      if (this.disableNext) {
        this.$refs.form.validate();
        this.showWarning = true;
        this.loading = false;
      } else {
        this.showWarning = false;
        let userCreated = false;
        let conceptId = await this.registerBrandSpace({
          email: this.email,
          brandSpaceSubdomain: this.brandSpaceSubdomain,
          brandSpaceLabel: this.brandSpaceLabel
        });
        if (conceptId) {
          const payload = {
            conceptId,
            type: "SYSTEM_ADMIN",
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            password: this.password
          };
          userCreated = await this.register(payload);
        }
        this.loading = false;
        if (userCreated) {
          this.setActiveItem({ to: "CreateSystemInvitations" });
        } else {
          this.errors.errorCreatingUser = true;
        }
      }
    },
    init() {
      this.email = this.email.toLowerCase();
      this.brandSpaceSubdomain = this.brandSpaceSubdomain.toLowerCase();
    }
  }
};
</script>
