import { make } from "vuex-pathify";
const state = {};
const getters = {
  ...make.getters(state)
};

const mutations = make.mutations(state);

const actions = {
  processWindow: async (context, { win }) =>
    new Promise((resolve, reject) => {
      let eventCount = 0;
      try {
        if (win) {
          //if (win === null || typeof win === "undefined")
          //reject("popup blocked");

          const checkForClosedInterval = window.setInterval(function() {
            if (!win || win.closed !== false) {
              // !== is required for compatibility with Opera
              clearInterval(checkForClosedInterval);
              reject("user closed");
            }
          }, 2000); //only need to check for closed every several seconds, the window events should usually trigger

          const handleMessage = event => {
            if (event.origin !== window.origin) {
              console.log("message from different origin:", event.origin);
              reject("bad origin");
            }

            if (event.data && event.data.conceptId) {
              //we received the data we were waiting for
              window.removeEventListener("message", handleMessage);
              resolve(event.data);
              if (win) win.close();
            } else {
              //data did not have the conceptId so was an 'internal' event of some sort
              eventCount++;
              if ((win && win.closed !== false) || eventCount > 10000) {
                //if data was empty and the window is now closed then there is nothing more to do
                //or if we hit 10k unhandled events we can assume something is wrong and we're in an infinte loop
                window.removeEventListener("message", handleMessage);
              }
            }
          };
          window.removeEventListener("message", handleMessage);
          window.addEventListener("message", handleMessage);

          if (win) win.focus();
        } else {
          throw new Error("no window provided to manage");
        }
      } catch (err) {
        console.error(err);
        reject();
      }
    })
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
