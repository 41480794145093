<template lang="pug">
    .unit-count
        p We just have two questions about how you use your {{prettyProviderName}} file.
        p.pl-8 How many units (businesses/stores/etc) do you keep in this file?
          UnitCountInput.ml-4(:value="unitCount", @change="unitCount = $event").mb-3
        BtnNext.mt-6(:loading="loading", @click="handleClick")
</template>

<script>
import { call } from "vuex-pathify";
import { forceFirstCapital } from "@/helpers.js";
import UnitCountInput from "@/components/Global/UnitCountInput.vue";
import BtnNext from "@/components/Global/BtnNext.vue";

export default {
  name: "UnitCount",
  components: { UnitCountInput, BtnNext },
  props: {
    type: { type: String, required: true },
    connectionId: { type: String, required: true },
    provider: { type: String, required: true },
    defaultUnitName: { type: String, required: false }
  },
  data() {
    return { loading: false, unitCount: 1 };
  },
  created() {
    this.init();
  },
  computed: {
    prettyProviderName() {
      return forceFirstCapital(this.provider);
    },
    propData() {
      return {
        type: this.type,
        connectionId: this.connectionId,
        provider: this.provider,
        unitCount: this.unitCount,
        unitNames: this.unitNames,
        defaultUnitName: this.defaultUnitName
      };
    }
  },
  methods: {
    ...call({
      updateUnitInfo: "Connections/updateUnitInfo",
      setActiveItem: "Onboarding/setActiveItem"
    }),
    async handleClick() {
      this.loading = true;
      if (this.unitCount && this.unitCount > 0) {
        await this.updateUnitInfo(this.propData);
        this.navigateNext();
      }
      this.loading = false;
    },
    navigateNext() {
      if (this.type === "ONBOARDING") {
        this.setActiveItem({ to: "ConnectionInfoTwo", data: this.propData });
      } else {
        this.$emit("nav", {
          to: "UnitNames",
          data: this.propData
        });
      }
    },
    navigatePrevious() {
      if (this.type === "ONBOARDING") {
        this.setActiveItem({ to: "ConnectUnit" });
      } else {
        this.$emit("nav", { to: "EXIT" });
      }
    },
    init() {
      if (!this.connectionId || !this.provider) this.navigatePrevious();
    }
  }
};
</script>
