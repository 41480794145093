<template lang="pug">
    .systems-table(v-show="!loading")
        
        .no-systems(v-if="!systems || systems.length < 1")
          v-card(color="grey lighten-4" flat).pa-5.ma-5.text-left
            p You do not have permission to view any systems
        
        h2.mt-10.mb-4 Systems
        .systems-all
          v-data-table.rounded(:headers="headers", :items="systems", :hide-default-footer="systems.length < 9")
            template(v-slot:no-data) No active systems
            
</template>

<script>
import { get, call } from "vuex-pathify";
export default {
  name: "SystemsTable",
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Brand Space Label",
          value: "brandSpaceLabel",
          align: "left"
        },
        {
          text: "Subdomain",
          value: "brandSpaceSubdomain",
          align: "left"
        },
        {
          text: "Subdomain Activated",
          value: "subdomainActivated",
          align: "center"
        },
        {
          text: "Billing Plan",
          value: "billingPlan",
          align: "left"
        },
        {
          text: "Created",
          value: "createdAt",
          align: "center"
        }
      ]
    };
  },
  watch: {
    loading(newVal) {
      this.$emit("change:loading", newVal);
    }
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      systemsData: "System/systems",
      prettyPrintTimestamp: "prettyPrintTimestamp"
    }),
    systems() {
      return this.systemsData.map(system => {
        return {
          ...system,
          createdAt: this.prettyPrintTimestamp(system.createdAt)
        };
      });
    }
  },
  methods: {
    ...call({
      getSystems: "System/getSystems"
    }),
    async init() {
      this.loading = true;
      // set header colors to standard header color
      this.headers.forEach(header => (header.class = "sectionHeaderColor"));
      await this.getSystems();
      console.log("this.systems:", this.systems);
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
::v-deep .v-data-table-header {
  th span {
    color: white;
    font-size: 1rem;
    font-weight: 400;
  }
}
</style>
