<template lang="pug">
    .unit-information
      OnboardingWrapper(:title="header.title", :subTitle="header.subTitle", :description="header.description", :stage="header.stage" :maxStages="header.maxStages")
        UnitNames(type="ONBOARDING", :connectionId="connectionId", :provider="provider" :unitCount="unitCount" :defaultUnitName="defaultUnitName")
</template>

<script>
import OnboardingWrapper from "@/components/Onboarding/OnboardingWrapper.vue";
import UnitNames from "@/components/Dashboard/Sections/Connections/UnitNames.vue";

export default {
  name: "ConnectionInfoTwo",
  components: { OnboardingWrapper, UnitNames },
  props: {
    connectionId: { type: String, required: true },
    provider: { type: String, required: true },
    unitCount: { type: Number, default: 1 },
    defaultUnitName: { type: String, required: false }
  },
  data() {
    return {
      header: {
        title: "Connection Details",
        subTitle: " ",
        description: "Thank you for connecting your data!",
        stage: 2,
        maxStages: 2
      },
      loading: false,
      errors: {}
    };
  }
};
</script>
