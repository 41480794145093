<template lang="pug">
    .manage-billing
        

        h2.mt-10.mb-6 Billing Details
        BillingDetailsTable

</template>

<script>
import BillingDetailsTable from "@/components/Dashboard/Sections/Billing/BillingDetailsTable.vue";

export default {
  name: "ManageBilling",
  title: "Billing Details",
  components: {
    BillingDetailsTable
  },
  data() {
    return {};
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
.max-width {
  max-width: 500px;
}
</style>
