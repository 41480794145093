import { make } from "vuex-pathify";
import Firebase from "firebase/app";
import { yyyyMmDd } from "@/helpers.js";

const EMPTY_REPORTS = {
  unitByUnit: {},
  system: {}
};

const state = {
  rangeType: "YEAR",
  startDate: new Date(new Date().getUTCFullYear(), 0, 1),
  endDate: new Date(new Date().getUTCFullYear(), 11, 31),
  loading: false,
  reports: EMPTY_REPORTS,
  reportsCurrentlyOnScreen: [],
  systemReportFields: [
    {
      key: "cogs",
      label: "COGS",
      color: "#0A0A5B"
    },
    { key: "grossProfit", label: "Gross Profit", color: "#60EBE0" },
    { key: "expenses", label: "Expenses", color: "#4040DB" },
    { key: "netOther", label: "Net Other", color: "#EC7952" },
    { key: "netIncome", label: "Net Profit", color: "#29CC7A" }
  ]
};
const getters = {
  ...make.getters(state)
};

const mutations = make.mutations(state);

const actions = {
  updateAllReportsCurrentlyOnScreen: async ({ getters, dispatch, commit }) => {
    commit("SET_LOADING", true);
    await dispatch("Connections/getConnections", {}, { root: true });
    commit("SET_REPORTS", EMPTY_REPORTS);

    for (const report of getters.reportsCurrentlyOnScreen) {
      await dispatch(report.action);
    }
    commit("SET_LOADING", false);
  },
  getSystemParsedReportData: async ({ getters, rootGetters, commit }) => {
    // await dispatch("Connections/getConnections", {}, { root: true });

    const userId = Firebase.auth().currentUser.uid;

    const { conceptId } = rootGetters["User/userData"];

    const getSystemParsedReportData = Firebase.functions().httpsCallable(
      "getSystemParsedReportData"
    );

    const { startDate, endDate, rangeType } = getters;

    const res = await getSystemParsedReportData({
      userId,
      conceptId,
      startYYYYMMDD: yyyyMmDd(startDate),
      endYYYYMMDD: yyyyMmDd(endDate),
      rangeType
    });

    const reports = getters.reports;
    commit("SET_REPORTS", { ...reports, system: res.data });
  },
  getUnitByUnitParsedReportData: async ({ getters, rootGetters, commit }) => {
    const { startDate, endDate, rangeType } = getters;
    const { conceptId } = rootGetters["User/userData"];

    const getUnitByUnitParsedReportData = Firebase.functions().httpsCallable(
      "getUnitByUnitParsedReportData"
    );

    const res = await getUnitByUnitParsedReportData({
      userId: Firebase.auth().currentUser.uid,
      conceptId,
      rangeType,
      startYYYYMMDD: yyyyMmDd(startDate),
      endYYYYMMDD: yyyyMmDd(endDate)
    });

    const reports = getters.reports;
    commit("SET_REPORTS", { ...reports, unitByUnit: res.data });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
