<template lang="pug">
    .system-report-for-unit-totals-top-line
        v-row
          v-col(cols="12" md="4").text-center
            .label.mb-2 Units
            .val.primary--text.font-weight-bold.no-wrap {{unitCount}}
          v-col(cols="12" md="4").text-center
            .label.mb-2 Avg Revenue
            .val.primary--text.font-weight-bold.no-wrap {{averageRevenue | displayValue(numDecimals)}}
          v-col(cols="12" md="4").text-center
            .label.mb-2 Avg Net Profit
            .val.primary--text.font-weight-bold.no-wrap {{averageNetProfit | displayValue(numDecimals)}}
</template>

<script>
import { floatToCurrency, roundFloat } from "@/helpers";
export default {
  name: "SystemReportForUnitTotalsTopLine",
  data() {
    return { numDecimals: 0 };
  },
  filters: {
    displayValue: (val, numDec) =>
      val.format === "percent"
        ? roundFloat(val.fltValue * 100, numDec).toString() + "%"
        : floatToCurrency(val.fltValue, numDec, val.currency)
  },
  props: {
    unitCount: { type: Number, required: true },
    averageRevenue: { type: Object, required: true },
    averageNetProfit: { type: Object, required: true }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 1rem;
  color: #4f4f4f;
}

.val {
  font-size: 2rem;
}
</style>
