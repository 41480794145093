<template lang="pug">
  .brand-space-details-form
      v-row
        v-col(cols="12" md="4")
          span Brand Space Name
        v-col(cols="12" md="4")
          v-text-field(outlined disabled=true label="Brand Space Label" :value="brandSpaceLabel" )
        v-col(cols="12" md="4")
          v-text-field(outlined disabled=true label="Brand Space Subdomain" :value="brandSpaceSubdomain" )


      v-row
        v-col(cols="12" md="4")
          span Is this a Service or Product based business?
        v-col(cols="12" md="4")
          v-select(outlined label="Type of Business" :items="options.typeOfBusiness" v-model="userSelections.typeOfBusiness" )

      v-row
        v-col(cols="12" md="4")
          span What do you call your Units?
        v-col(cols="12" md="4")
          v-select(outlined label="Unit Label" :items="options.unitLabel" v-model="userSelections.unitLabel" )
        v-col(cols="12" md="4")
          v-text-field(v-show="showUnitLabelOther" outlined label="Your Label" v-model="userSelections.unitLabelOther" )

      v-row
        v-col(cols="12" md="4")
          span How do you refer to your {{UnitLabel}} income?
        v-col(cols="12" md="4")
          v-select(outlined label="Income Label" :items="options.revenueLabel" v-model="userSelections.revenueLabel" )

      v-row
        v-col(cols="12" md="4")
          span How many total {{UnitLabel}}?
        v-col(cols="12" md="4")
          v-text-field(outlined label="Open & Operating" v-model="userSelections.unitsActive" )
        v-col(cols="12" md="4")
          v-text-field(outlined label="Pending/Sold/Under Construction" v-model="userSelections.unitsPending" )

      v-row
        v-col(cols="12" md="4")
          span How many total Corporate/Brand owned {{UnitLabel}}?
        v-col(cols="12" md="4")
          v-text-field(outlined label="Open & Operating" v-model="userSelections.unitsCorpActive" )
        v-col(cols="12" md="4")
          v-text-field(outlined label="Pending/Sold/Under Construction" v-model="userSelections.unitsCorpPending" )

      v-row
        v-col(cols="12" md="4")
          span How many total Owners?
        v-col(cols="12" md="4")
          v-text-field(outlined label="Open & Operating" v-model="userSelections.ownersActive" )
        v-col(cols="12" md="4")
          v-text-field(outlined label="Pending/Sold/Under Construction" v-model="userSelections.ownersPending" )
      
      v-row
        v-col(cols="12" md="4")
          span How do your {{UnitLabel}} manage Inventory?
        v-col(cols="12" md="4")
          v-select(outlined label="Inventory Tracking" :items="options.inventoryTracking" v-model="userSelections.inventoryTracking" )


      v-row
        v-btn(outlined color="accent", class="primary-extra" :loading="saving" @click="handleClick")
          v-icon.mr-2(small) mdi-content-save
          | Save Brand Space Details
        //v-progress-circular.ml-2(indeterminate v-show="saving" color="accent" size="36")
    
      v-row
        v-col(cols="12" v-if="notification")
          v-fade-transition
            v-alert(type="success" outlined) {{notification}}
        v-col(cols="12" v-if="error")
          v-fade-transition
            v-alert(type="error" outlined) {{error}}
</template>

<script>
import { get, call } from "vuex-pathify";
export default {
  name: "BrandSpaceMetadataForm",
  data() {
    return {
      loading: false,
      saving: false,
      notification: "",
      error: "",
      options: {
        typeOfBusiness: [
          "",
          "Service-based",
          "Product-based",
          "Primarily Services with some Products",
          "Primarily Products with some Services"
        ],
        unitLabel: [
          "Units",
          "Restaurants",
          "Stores",
          "Offices",
          "Locations",
          "Territories",
          "Centers",
          "Studios",
          "Other"
        ],
        revenueLabel: ["Income", "Revenue", "Sales"],
        inventoryTracking: [
          "Don't have inventory",
          "Have basic inventory but don't track with software",
          "Track with Accounting Software (QBO/Xero/FreshBooks)",
          "Track with third-party inventory software"
        ]
      },
      userSelections: {
        typeOfBusiness: "",
        unitLabel: "",
        unitLabelOther: "",
        revenueLabel: "",
        inventoryTracking: "",
        unitsActive: 0,
        unitsPending: 0,
        unitsCorpActive: 0,
        unitsCorpPending: 0,
        ownersActive: 0,
        ownersPending: 0
      }
    };
  },
  watch: {
    loading(newVal) {
      this.$emit("change:loading", newVal);
    }
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      conceptId: "User/userData@conceptId",
      serverBrandSpaceMetadata: "System/brandSpaceMetadata@",
      brandSpaceLabel: "System/brandSpaceData@brandSpaceLabel",
      brandSpaceSubdomain: "System/brandSpaceData@brandSpaceSubdomain"
    }),
    showUnitLabelOther() {
      return this.userSelections.unitLabel === "Other";
    },
    UnitLabel() {
      return !this.showUnitLabelOther
        ? this.userSelections.unitLabel
        : this.userSelections.unitLabelOther.length == 0
        ? "Units"
        : this.userSelections.unitLabelOther;
    }
  },
  methods: {
    ...call({
      loadBrandSpaceMetadata: "System/loadBrandSpaceMetadata",
      updateBrandSpaceMetadata: "System/updateBrandSpaceMetadata"
    }),
    handleClick() {
      this.notification = "";
      this.error = "";
      this.saveBrandSpaceMetadata();
    },
    async saveBrandSpaceMetadata() {
      this.saving = true;
      const res = await this.updateBrandSpaceMetadata({
        conceptId: this.conceptId,
        brandSpaceMetadata: this.userSelections
      });
      if (res && res.data && res.data.updated) {
        this.notification = "Saved Brand Space details.";
        setTimeout(() => {
          this.notification = "";
        }, 3000);
      } else {
        this.error = "Saving failed, please check your values and try again.";
      }
      this.saving = false;
    },
    async init() {
      this.loading = true;
      await this.loadBrandSpaceMetadata(this.conceptId);
      if (this.serverBrandSpaceMetadata) {
        this.userSelections = this.serverBrandSpaceMetadata;
      } else {
        this.error =
          "Failed to load the existing settings, please try again later.";
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
