<template lang="pug">
    .popup-date-picker.d-flex.justify-end
        //- pre max = {{max}}
        .p-fixed
            pre.debug startDate {{startDate.toString()}}
            pre.debug endDate {{endDate.toString()}}
        v-menu(ref="menu", v-model="menu" attach=".popup-date-picker" :close-on-content-click="false", transition="scale-transition", offset-y, left, :min-width="rangeType === 'YEAR' ? 'auto': '300px'", eager, @input="handleOpenMenu")
            template(v-slot:activator="{ on, attrs }")
                .date-label(v-bind="attrs", v-on="on") {{dateLabel}}
            v-date-picker( flat :value="today"  ref="picker", :close-on-content-click="true", :max="max", :min="min", :width="rangeType === 'YEAR' ? '200px': '300px'", elevation="1",  @click:month="handleHideMenu", @click:year="handleHideMenu", @update:picker-date="handleChange")
</template>

<script>
import { lastDayOfDateMonth } from "@/helpers";
import { get, sync, call } from "vuex-pathify";
export default {
  name: "PopupDatePicker",
  components: {},
  watch: {
    rangeType(newVal) {
      this.initPicker(newVal);
    }
  },
  data() {
    return {
      today: null,
      menu: false,
      max: new Date().toISOString().substr(0, 10),
      min: new Date(new Date().getUTCFullYear() - 5, 0, 2).toISOString(),
      key: 0,
      year: new Date().getFullYear(),
      month: null
    };
  },
  mounted() {
    this.initPicker(this.rangeType);
  },
  computed: {
    ...get({ rangeType: "Reports/rangeType" }),
    ...sync({ startDate: "Reports/startDate", endDate: "Reports/endDate" }),
    dateLabel() {
      if (this.rangeType === "YEAR") {
        return this.startDate.getFullYear();
      } else {
        const options = {
          year: "numeric",
          month: "short"
        };

        return this.endDate.toLocaleDateString("en-Us", options);
      }
    }
  },
  methods: {
    ...call({
      updateAllReportsCurrentlyOnScreen:
        "Reports/updateAllReportsCurrentlyOnScreen"
    }),
    initPicker(type) {
      if (!this.$refs.picker) return;
      if (type === "YEAR") {
        this.$refs.picker.internalActivePicker = "YEAR";
      } else {
        this.$refs.picker.internalActivePicker = "MONTH";
      }
    },

    handleChange(e) {
      this.initPicker(this.rangeType);

      if (e) {
        const year = e.toString().substr(0, 4);
        const monthRaw = e.split("-")[1];
        const month = monthRaw ? parseInt(monthRaw) - 1 : 0;

        if (this.rangeType === "YEAR") {
          if (e) {
            this.startDate = new Date(year, 0, 1, 0);
            this.endDate = new Date(year, 11, 31, 23, 59);
          }
        } else {
          this.startDate = new Date(year, month, 1, 0);
          this.endDate = new Date(
            year,
            month,
            lastDayOfDateMonth(year, month),
            23,
            59
          );
        }

        this.year = year;
        this.month = month;

        this.updateAllReportsCurrentlyOnScreen();
      }
    },
    handleHideMenu() {
      this.menu = false;
    },
    handleOpenMenu() {
      this.initPicker(this.rangeType);
      this.key++;
    }
  }
};
</script>

<style scoped>
.p-fixed {
  position: fixed;
  top: 10px;
  left: 10px;
  background: yellow;
  z-index: 99;
}

.date-label {
  width: 9rem;
  background: #d9dbdf;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 0.765rem 2rem;
}

::v-deep .text-field fieldset {
  display: none;
}

pre.debug {
  display: none;
}
</style>
