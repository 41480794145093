<template lang="pug">
    .connect-buttons
        template(v-if="showPopupWarning")
          v-card.popup-warning.warning--text(color="grey lighten-3").pa-5.ma-3
            p Connect to {{softwareName}} in the pop-up authorization window.   
            p If you do not see the pop-up window, check your other tabs and any additional monitors you have connected
        
        template(v-else-if="showPopupBlocked")
          v-card.popup-warning.warning--text(color="grey lighten-3").pa-10.ma-2
            p The {{softwareName}} pop-up was blocked (or you exited the process).
            p Look for an icon or warning in your browser and <strong>allow pop-ups for {{host}}</strong>
            v-btn(@click="showPopupBlocked = false") Try Again

        template(v-else-if="showPopupFailed")
          v-card.popup-warning.warning--text(color="grey lighten-3").pa-10.ma-2
            p The {{softwareName}} pop-up authorization did not complete or you exited the process.
            p Please complete all of the steps required by {{softwareName}} in order to complete the connection.
            v-btn(@click="showPopupFailed = false") Try Again

        template(v-else)
          ConnectToSourceButton(type="QUICKBOOKS" @click="handleConnection('QUICKBOOKS')").mb-3
          ConnectToSourceButton(disabled type="XERO", @click="handleConnection('XERO')").mb-3
          ConnectToSourceButton(disabled type="FRESHBOOKS")
</template>

<script>
import { get, call } from "vuex-pathify";
import { forceFirstCapital } from "@/helpers.js";
import ConnectToSourceButton from "@/components/Global/ConnectToSourceButton.vue";
export default {
  name: "ConnectButtons",
  components: { ConnectToSourceButton },
  data() {
    return {
      selectedApp: "",
      showPopupWarning: false,
      showPopupFailed: false,
      showPopupBlocked: false
    };
  },
  computed: {
    ...get({
      host: "host"
    }),
    softwareName() {
      return this.selectedApp === ""
        ? "your accounting software"
        : forceFirstCapital(this.selectedApp);
    }
  },
  methods: {
    ...call({
      getConnectionUrl: "Connections/getConnectionUrl",
      processWindow: "Window/processWindow"
    }),
    handleConnection(app) {
      const cloudCompanyName =
        app === "QUICKBOOKS" ? "Intuit" : app === "Xero" ? "Xero" : "";
      const cloudAppName = app === "QUICKBOOKS" ? "QuickBooks" : "";
      const initialUrl = `/connections/initial?cloudCompanyName=${cloudCompanyName}&cloudAppName=${cloudAppName}`;
      const win = window.open(
        initialUrl,
        "wndConnectAccounting",
        "menubar=0,scrollbars=1,width=780,height=900,top=10"
      );
      this.handleWindowConnection(win, app);
    },
    async handleWindowConnection(win, app) {
      this.selectedApp = app;
      this.showPopupFailed = false;
      this.showPopupBlocked = false;
      this.showPopupWarning = true;
      this.$emit("connecting", true);
      const url = await this.getConnectionUrl({ app });
      let data;
      if (url) {
        win.location.href = url;
        try {
          data = await this.processWindow({ win });
        } catch (err) {
          data = { error: err };
        }
      } else data = { error: "no app url" };

      if (data) {
        if (data.connectionId) this.$emit("nav", { to: "UnitCount", data });
        else if (
          ["popup blocked", "popup failed", "bad origin"].includes(data.error)
        )
          this.showPopupBlocked = true;
        else if (["user closed", "no app url"].includes(data.error))
          this.showPopupFailed = true;
        else this.showPopupFailed = true;
      }
      this.$emit("connecting", false);
      this.showPopupWarning = false;
    }
  }
};
</script>
