<template lang="pug">
    .system-report-for-unit-totals-top-line
        v-row
          v-col(cols="12" md="4").text-center
            .label.mb-2 Units
            .val.primary--text.font-weight-bold.no-wrap {{unitCount}}
          v-col(cols="12" md="4").text-center
            .label.mb-2 Revenue
            .val.primary--text.font-weight-bold.no-wrap {{revenue | displayValue(numDecimals)}}
          v-col(cols="12" md="4").text-center
            .label.mb-2 Net Profit
            .val.primary--text.font-weight-bold.no-wrap {{netProfitPercent}}%
</template>

<script>
import { floatToCurrency } from "@/helpers.js";
export default {
  name: "UnitByUnitReportTopLine",
  data() {
    return { numDecimals: 0 };
  },
  filters: {
    displayValue: (val, numDec) =>
      floatToCurrency(val.fltValue, numDec, val.currency)
  },
  props: {
    unitCount: { type: Number, required: true },
    revenue: { type: Object, required: true },
    netProfitPercent: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 1rem;
  color: #4f4f4f;
}

.val {
  font-size: 2rem;
}
</style>
