<template lang="pug">
    .report-wrapper.flex-grow-1(:style="{border: '1px solid #989CA3', borderRadius: '5px'}")
        v-card(flat)
            .subtitle.white--text.pa-3(class="sectionHeaderColor" v-if="subtitle") {{subtitle}}
            TabSelector(v-if="activeTab && tabs && tabs.length > 0", :items="tabs", :value="activeTab", @click="$emit('change:tab', $event)")
            v-card-text
              slot
</template>

<script>
import TabSelector from "@/components/Dashboard/Sections/Home/TabSelector.vue";
export default {
  name: "ReportWrapper",
  components: { TabSelector },
  props: {
    subtitle: { type: String, default: null },
    tabs: { type: Array, default: null },
    activeTab: { type: String, default: null }
  }
};
</script>
