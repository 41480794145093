<template lang="pug">
    .doughnut-chart
      #chartContainer
        v-skeleton-loader(v-if="loading", type="image")
        
</template>
<script>
import Highcharts from "highcharts";
import { get } from "vuex-pathify";
import { waitForElemToExist, floatToCurrency } from "@/helpers";
export default {
  name: "DoughnutChart",
  components: {},
  props: {
    averages: { type: Object, required: true }
  },
  data() {
    return { loading: true, chart: null };
  },
  mounted() {
    // Sometimes when switching between tabs highcharts fails to load into the container on mounted() for some reason
    setTimeout(() => {
      this.initChart();
    }, 100);
    setTimeout(() => {
      if (!document.querySelector("#chartContainer .highcharts-container"))
        this.initChart();
    }, 500);
    setTimeout(() => {
      if (!document.querySelector("#chartContainer .highcharts-container"))
        this.initChart();
    }, 1000);
  },
  beforeDestroy() {
    if (this.chart) this.chart.destroy();
  },
  computed: {
    ...get({
      reportData: "Reports/reports@system",
      systemReportFields: "Reports/systemReportFields"
    }),
    series() {
      if (this.systemReportFields) {
        const data = this.systemReportFields.map(field => {
          if (field.key === "grossProfit") return {};
          return {
            name: field.label,
            y: this.reportData.sumsAndAverages.averages.asPercent[field.key],
            color: field.color,
            dataLabels: {
              style: {
                color: "#4F5A6C",
                fontWeight: "normal"
              }
            }
          };
        });
        const onlyWithValues = data.filter(itm => itm && itm.y);
        return [
          {
            name: "Gross Profit",
            colorByPoint: true,
            data: [
              {
                name: "Gross Profit",
                dataLabels: {
                  distance: -175
                },
                color: this.systemReportFields.find(
                  field => field.key === "grossProfit"
                ).color,
                y: this.reportData.sumsAndAverages.averages.asPercent
                  .grossProfit
              },
              {
                name: "Percent",
                dataLabels: {
                  format: ""
                },
                color: "white",
                y:
                  1 -
                  this.reportData.sumsAndAverages.averages.asPercent.grossProfit
              }
            ],
            size: "65%",
            innerSize: "75%",
            zIndex: 1
          },
          {
            name: "System Averages",
            colorByPoint: true,
            data: [...onlyWithValues],
            innerSize: "75%",
            zIndex: 2
          }
        ];
      } else return [{ name: "Loading" }];
    }
  },
  methods: {
    async initChart() {
      await waitForElemToExist("#chartContainer");
      this.chart = new Highcharts.chart("chartContainer", {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          paddingTop: 0
        },
        credits: false,
        title: {
          text:
            "Avg Net Profit<br><br>" +
            floatToCurrency(
              this.reportData.sumsAndAverages.averages.asFloat.netIncome,
              0
            ),
          align: "center",
          verticalAlign: "middle",
          y: 20,
          style: {
            fontSize: 14,
            fontWeight: "bold"
          }
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b>"
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              alignTo: "plotEdges",
              format: "<b>{point.name}</b>: {point.percentage:.1f} %"
            }
          }
        },
        series: this.series,
        events: {
          load: () => (this.loading = false)
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: false,
                yAxis: {
                  labels: {
                    align: "left",
                    x: 0,
                    y: -5
                  },
                  title: {
                    text: null
                  }
                },
                subtitle: {
                  text: null
                }
              }
            }
          ]
        }
      });
    }
  }
};
</script>

<style scoped></style>
