<template lang="pug">
    .finished-connecting
        p All set! Thanks for connecting your unit data.
        p If you have more QuickBooks, Xero or FreshBooks files, click the button below to connect the next one.
        v-btn(outlined block large @click="handleClick").mt-10.mb-3 Connect Another File
        //- BtnNext(text="Finish" :icon="null" :loading="loading" @click="handleClickFinish")
        p.smaller.mt-8 Based on your Brand Space settings, owners do not have access to view dashboards. But we'll email you as soon as your admin enables your access.
</template>

<script>
import { sync, call } from "vuex-pathify";
import BtnNext from "@/components/Global/BtnNext.vue";
export default {
  name: "FinishedConnecting",
  components: { BtnNext },
  props: {
    type: { type: String, required: true }
  },
  data() {
    return {};
  },
  mounted() {
    this.handleClickFinish();
  },
  computed: {
    ...sync({})
  },
  methods: {
    ...call({
      setActiveConnectNewDataSourceComponent:
        "Nav/setActiveConnectNewDataSourceComponent",
      setActiveItem: "Onboarding/setActiveItem",
      showConnectNewDataSourceModal:
        "Nav/showConnectNewDataSourceModal"
    }),
    handleClick() {
      if (this.type === "ONBOARDING") {
        this.setActiveItem({ to: "ConnectUnit" });
      } else {
        this.setActiveConnectNewDataSourceComponent("ConnectButtons");
      }
    },
    handleClickFinish() {
      if (this.type === "ONBOARDING") {
        this.$emit("click:finish");
      } else {
        this.showConnectNewDataSourceModal(false);
      }
    }
  }
};
</script>
