<template lang="pug">
    input(type="number" :value="value" placeholder="?" min="1", max="100" @input="handleChange" style="width:60px")
</template>

<script>
import { ensureInteger } from "@/helpers.js";

export default {
  name: "UnitCountInput",
  props: { value: { type: Number, required: true } },
  data() {
    return {};
  },
  methods: {
    handleChange(e) {
      this.$emit("change", ensureInteger(e.target.value, 1));
    }
  }
};
</script>

<style scoped>
input {
  background: #e5e5e5;
  border: 1px solid rgb(63, 63, 63);
  text-align: right;
  padding: 0.25rem;
}
</style>
