<template lang="pug">
    .invites-table
        v-data-table(:loading="loading" :headers="headers", :items="items", :hide-default-footer="items.length < 9")
          template(v-slot:item.opened="{ item }")
            template(v-if="item.opened === true")
              v-icon(color="success" small) mdi-check-circle
            span(v-else) {{item.opened}}

          template(v-slot:item.completed="{ item }")
            template(v-if="item.completed === true")
              v-icon(color="success" small) mdi-check-circle
            span(v-else) {{item.completed}}
            
          template(v-slot:item.sendReminder="{ item }")
            v-btn(v-if="item.completed !== true" text small color="accent", :disabled="disabledReminderButtons.includes(item.id)" @click="handleSendReminderInvite({inviteId: item.id})") Send Reminder

          template(v-slot:item.delete="{ item }")
            v-tooltip(left)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon small v-on="on" @click="handleDeleteInvite({inviteId: item.id})")
                  v-icon(:color="canDelete({inviteId: item.id}) ? 'error' : 'grey lighten-3'" small) mdi-trash-can
              .tooltip-text
                template(v-if="canDelete({inviteId: item.id})") Permanently delete this invitee?
                template(v-else)
                  p.warning--text This invitee has one or more connections.
                  p.warning--text To delete invitee, first delete their Connection.
</template>

<script>
import { get, call } from "vuex-pathify";
import sumby from "lodash.sumby";
export default {
  name: "InvitesTable",
  props: { loading: { type: Boolean, default: false } },
  data() {
    return {
      headers: [
        {
          text: "Email Address",
          value: "email",
          align: "left"
        },
        {
          text: "Opened",
          value: "opened",
          align: "center"
        },
        {
          text: "Completed",
          value: "completed",
          align: "center"
        },
        {
          text: "# of Units",
          value: "unitCount",
          align: "center",
          width: "200"
        },
        {
          text: "",
          value: "sendReminder",
          align: "center"
        },
        {
          text: "",
          value: "delete",
          align: "center"
        }
      ],
      disabledReminderButtons: [],
      sendDuplicateRemindersDelay: 45000,
      pseudoDeletedInvites: []
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      userRole: "User/userData@type",
      invites: "Invitations/invites",
      connections: "Connections/connections"
    }),
    connectionCreators() {
      return this.connections.map(conn => {
        //return conn.createdBy.id;
        return conn.id;
      });
    },
    items() {
      if (!this.connections) return [];
      let items = this.invites
        .map(invite => {
          const inviteConnections = this.connections.filter(
            conn => conn.creatorEmail === invite.email
          );
          const sumOfUnitsInRelatedTokens = inviteConnections
            ? sumby(inviteConnections, "unitCount")
            : null;
          return {
            id: invite.id,
            email: invite.email,
            opened: invite.inviteeClickedToOpenEmail || "--",
            completed: invite.inviteeCompletedOnboarding || "--",
            unitCount: sumOfUnitsInRelatedTokens || "--"
          };
        })
        .filter(invite => !this.pseudoDeletedInvites.includes(invite.id));
      return items;
    }
  },
  methods: {
    ...call({
      deleteInvite: "Invitations/deleteInvite",
      sendReminderInvite: "Invitations/sendReminderInvite",
      getConnections: "Connections/getConnections"
    }),
    canDelete({ inviteId }) {
      return (
        inviteId &&
        (["SYSTEM_ADMIN", "ZEEWISE_ADMIN"].includes(this.userRole) ||
          !this.connectionCreators.includes(inviteId))
      );
    },

    handleDeleteInvite({ inviteId }) {
      if (!this.connectionCreators.includes(inviteId)) {
        this.pseudoDeletedInvites.push(inviteId);
        this.deleteInvite({ inviteId });
      }
    },
    handleSendReminderInvite({ inviteId }) {
      this.disabledReminderButtons.push(inviteId);
      this.sendReminderInvite({ inviteId });
      setTimeout(() => {
        this.disabledReminderButtons = this.disabledReminderButtons.filter(
          itm => itm !== inviteId
        );
      }, this.sendDuplicateRemindersDelay);
    },
    async init() {
      // set header colors to standard header color
      this.headers.forEach(header => (header.class = "sectionHeaderColor"));

      await this.getConnections();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
::v-deep .v-data-table-header {
  th span {
    color: white;
    font-size: 1rem;
    font-weight: 400;
  }
}
</style>
