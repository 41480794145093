import { make } from "vuex-pathify";
import Firebase from "firebase/app";
import "firebase/auth";
import { db } from "@/firebase.js";

import { isValidEmail } from "@/helpers.js";

const state = {
  invites: [],
  registration: {
    invitationCode: "",
    invitationCodeValidated: false,
    convertedUserId: null
  },
  events: {
    inviteeClickedToOpenEmail: false,
    inviteeCompletedOnboarding: false
  }
};
const getters = {
  ...make.getters(state)
};

const mutations = make.mutations(state);

const actions = {
  sendInvites: async ({ rootGetters }) => {
    const hasValidEmails = rootGetters[
      "Onboarding/registration"
    ].inviteEmailAddresses.some(
      email => email && email.length > 1 && isValidEmail(email)
    );

    if (hasValidEmails) {
      const addInvites = Firebase.functions().httpsCallable("addInvites");

      return await addInvites({
        userId: Firebase.auth().currentUser.uid,
        emailAddresses:
          rootGetters["Onboarding/registration"].inviteEmailAddresses
      });
    } else {
      console.error("None of the provided invitee email addresses were valid");
    }
  },

  validateInvitationCode: async ({ rootGetters, getters, commit }) => {
    const getInviteByInvitationCodeAsync = Firebase.functions().httpsCallable(
      "getInviteByInvitationCode"
    );

    const { conceptId } = rootGetters["Onboarding/registration"];

    const { invitationCode } = getters.registration;

    const res = await getInviteByInvitationCodeAsync({
      conceptId,
      invitationCode
    });

    const inviteData = res.data.invite;
    commit("SET_REGISTRATION", {
      ...getters.registration,
      convertedUserId: inviteData && inviteData.convertedUserId,
      invitationCodeValidated: inviteData ? false : true
    });
    return inviteData;
  },
  updateInviteDetails: async ({ rootGetters, getters }) => {
    const updateInviteDetails = Firebase.functions().httpsCallable(
      "updateInviteDetails"
    );

    const { email, firstName, lastName } = rootGetters[
      "Onboarding/registration"
    ];

    const { invitationCode } = getters.registration;

    const {
      inviteeClickedToOpenEmail,
      inviteeCompletedOnboarding
    } = getters.events;

    const res = await updateInviteDetails({
      invitationCode,
      email,
      firstName,
      lastName,
      inviteeClickedToOpenEmail,
      inviteeCompletedOnboarding
    });

    const success = res && res.data && res.data.result;
    return success;
  },

  deleteInvite: async (context, { inviteId }) => {
    const deleteInvite = Firebase.functions().httpsCallable("deleteInvite");

    const res = await deleteInvite({
      userId: Firebase.auth().currentUser.uid,
      inviteId
    });
    return res;
  },
  sendReminderInvite: async (context, { inviteId }) => {
    const sendReminderInvite = Firebase.functions().httpsCallable(
      "sendReminderInvite"
    );

    const res = await sendReminderInvite({
      sendingUserId: Firebase.auth().currentUser.uid,
      inviteId
    });

    return res;
  },

  bindInvites: async ({ commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      let query;
      const { type, conceptId } = rootGetters["User/userData"];
      if (type === "INVITEE") {
        const thisUserRef = db
          .collection("users")
          .doc(Firebase.auth().currentUser.uid);
        query = db
          .collection("invites")
          .where("convertedUser", "==", thisUserRef);
      } else if (["SYSTEM_ADMIN", "ZEEWISE_ADMIN"].includes(type)) {
        query = db.collection("invites").where("conceptId", "==", conceptId);
      }

      query.onSnapshot(
        async querySnapshot => {
          let invites = [];
          for (const doc of querySnapshot.docs) {
            invites.push({
              id: doc.id,
              ...doc.data()
            });
          }

          commit("SET_INVITES", invites);
          resolve();
        },
        err => {
          console.log(`Encountered error: ${err}`);
          reject(err);
        }
      );
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
