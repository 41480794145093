<template lang="pug">
    .cogs-report-table
      //- pre.debug {{report}}
      TableWrapper(:headers="headers", :items="items", :hide-default-footer="items.length < 9", canExport)
      

</template>

<script>
import { get, call } from "vuex-pathify";
import { floatToCurrency } from "@/helpers";
// import sumby from "lodash.sumby";
import TableWrapper from "@/components/Dashboard/TableWrapper.vue";
export default {
  name: "UnitByUnitReportTable",
  components: { TableWrapper },
  data() {
    return {
      numDecimals: 0,
      headers: [
        {
          text: "Connection Name",
          value: "unitNames",
          align: "left"
        },
        {
          text: "Units Contributing",
          value: "unitCount",
          align: "left"
        },
        {
          text: "Total Revenue ($)",
          value: "totalIncome",
          align: "left"
        },
        {
          text: "COGS ($)",
          value: "cogs",
          align: "left"
        },
        {
          text: "Gross Profit ($)",
          value: "grossProfit",
          align: "left"
        },
        {
          text: "Expenses ($)",
          value: "expenses",
          align: "left"
        },
        {
          text: "Net Other ($)",
          value: "netOther",
          align: "left"
        },
        {
          text: "Net Profit ($)",
          value: "netIncome",
          align: "left"
        }
      ]
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      connections: "Connections/connections",
      availableReportData: "Reports/reports@unitByUnit"
    }),
    items() {
      let res = this.availableReportData.map(reportParsedData => {
        if (!reportParsedData || !reportParsedData.connectionId) return null;

        const connectionId = reportParsedData.connectionId;

        const {
          cogs,
          netIncome,
          netOther,
          grossProfit,
          expenses,
          totalIncome,
          currency
        } = reportParsedData;

        const connection = this.connections.find(
          conn => conn.id === connectionId
        );

        const { unitCount, unitNames, creatorEmail } = connection;
        const prettyNames =
          unitNames && unitNames.length > 0
            ? unitNames.join(", ")
            : creatorEmail;
        const item = {
          unitCount,
          unitNames: prettyNames,
          currency: currency,
          cogs: cogs ? this.asDisplayCurrency(cogs, currency) : 0,
          netIncome: netIncome
            ? this.asDisplayCurrency(netIncome, currency)
            : 0,
          netOther: netOther ? this.asDisplayCurrency(netOther, currency) : 0,
          grossProfit: grossProfit
            ? this.asDisplayCurrency(grossProfit, currency)
            : 0,
          expenses: expenses ? this.asDisplayCurrency(expenses, currency) : 0,
          totalIncome: totalIncome
            ? this.asDisplayCurrency(totalIncome, currency)
            : 0
        };

        return item;
      });
      return res.filter(Boolean);
    }
  },
  methods: {
    ...call({
      getUnitByUnitParsedReportData: "Reports/getUnitByUnitParsedReportData"
    }),
    asDisplayCurrency(parsedDataValue, currency) {
      if (!parsedDataValue || !parsedDataValue.value) return "";
      else
        return floatToCurrency(
          parsedDataValue.fltValue,
          this.numDecimals,
          currency
        );
    },
    init() {
      this.getUnitByUnitParsedReportData();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
::v-deep .v-data-table-header {
  th span {
    color: white;
    font-size: 1rem;
    font-weight: 400;
  }
  th:first-of-type {
    border-top-left-radius: 4px;
  }
  th:last-of-type {
    border-top-right-radius: 4px;
  }
}
</style>
