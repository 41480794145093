<template lang="pug">
    .btn-add-invitee
        v-btn(:icon="mini" :depressed="!mini" color="primary" small @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave", @click="handleClick")
            v-icon(v-if="mini") mdi-plus-circle
            span(v-else) + Add Another Invitee

</template>

<script>
import { sync } from "vuex-pathify";
export default {
  name: "BtnAddInvitee",
  data() {
    return {
      mini: true
    };
  },
  computed: {
    ...sync({
      inviteEmailAddresses: "Onboarding/registration@inviteEmailAddresses"
    })
  },
  methods: {
    handleMouseEnter() {
      this.mini = false;
    },
    handleMouseLeave() {
      this.mini = true;
    },
    handleClick() {
      this.inviteEmailAddresses.push("");
    }
  }
};
</script>
