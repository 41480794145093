<template lang="pug">
    .unit-name-input
        v-text-field(outlined :placeholder="placeholder", :label="`Unit ${index} Name`", :value="defaultName" dense, @change="$emit('change', $event)")
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "UnitNameInput",
  props: {
    index: { type: Number, required: true },
    defaultName: { type: String, required: false }
  },
  computed: {
    ...get({ firstName: "User/userData@firstName" }),
    placeholder() {
      let name =
        this.firstName && this.firstName.length > 0
          ? this.firstName + "'s"
          : "Your";
      const index = this.index && this.index > 0 ? "#" + this.index : "";
      return `${name} Store ${index}`;
    }
  }
};
</script>
