<template lang="pug">
    .section-invite-list
        DashboardSectionWrapper(:subtitle="$options.subtitle" :loading="loading")
        InviteStats.mb-12
        InviteTable(:loading="loading")
</template>

<script>
import { call, get } from "vuex-pathify";
import DashboardSectionWrapper from "@/components/Dashboard/Sections/DashboardSectionWrapper.vue";
import InviteStats from "@/components/Dashboard/Sections/Invites/InviteStats.vue";
import InviteTable from "@/components/Dashboard/Sections/Invites/InvitesTable.vue";
export default {
  name: "InviteList",
  title: "Invites",
  subtitle: "Overview",
  mastheadButton: {
    text: "+ Send New Invites",
    to: "/Onboarding/CreateSystemInvitations"
  },
  components: { DashboardSectionWrapper, InviteStats, InviteTable },
  data() {
    return { loading: true };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({ invites: "Invitations/invites" })
  },
  methods: {
    ...call({ bindInvites: "Invitations/bindInvites" }),
    async init() {
      await this.bindInvites();
      this.loading = false;
    }
  }
};
</script>
