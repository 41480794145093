import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import Firebase from "firebase/app";

Vue.use(VueRouter);

const loginStatus = async () => {
  try {
    const firebaseId = Firebase.auth().currentUser
      ? Firebase.auth().currentUser.uid
      : null;

    const hasUserData =
      firebaseId &&
      store &&
      store.getters &&
      store.getters["User/userData"] &&
      store.getters["User/userData"].id
        ? store.getters["User/userData"].id
        : null;

    return { firebaseId, hasUserData: !!hasUserData };
  } catch (err) {
    console.error(err);
    return false;
  }
};

const doLoginUser = async id =>
  await store.dispatch("User/bindUserData", { id });

const routes = [
  {
    path: "/",
    beforeEnter: async (to, from, next) => {
      const { firebaseId } = await loginStatus();
      if (firebaseId) {
        next("/dashboard/home");
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/onboarding/:page",
    component: () =>
      import(
        /* webpackChunkName: "Onboarding" */ "../views/Onboarding/OnboardingPage.vue"
      )
  },
  {
    path: "/connections/connection_callback",
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackChunkName: "Connections" */ "../views/Connections/ConnectionCallback.vue"
      )
  },
  {
    path: "/connections/initial",
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackChunkName: "Connections" */ "../views/Connections/ConnectionsPage.vue"
      )
  },
  {
    path: "/dashboard/:page",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "../views/Dashboard/DashboardPage.vue"
      )
  },
  {
    path: "/zwadmin/:page",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "Admin" */ "../views/Dashboard/DashboardPage.vue"
      )
  },
  {
    name: "login",
    path: "/login",
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "UserAccount" */ "../views/User/Login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: { requiresAuth: false },
    beforeEnter: async (to, from, next) => {
      await store.dispatch("User/logout");
      next("/login");
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const isAuthPath = to.path.toLowerCase().indexOf("/logout") >= 0;
  //currently admins have several pages shared with onboarding that are nonAuth pages
  const isAllowedNonAuth =
    to.path.toLowerCase().indexOf("/connections/connection_callback") >= 0 ||
    to.path.toLowerCase().indexOf("/onboarding/createsysteminvitations") >= 0 ||
    to.path.toLowerCase().indexOf("/connections/initial") >= 0;

  const { hasUserData, firebaseId } = await loginStatus();

  //if user is logged in and trying to go to a non-auth page (onboarding/login/etc) then send them to the Home dashboard
  //this forces them to logout first if they really want to hit routes for non-logged in users
  //ignore Logout (so logout can actually occur) and Oauth routes since QBO/Xero/FreshBooks will be routing from external, third-party referer
  if (!isAuthPath && !requiresAuth && firebaseId && !isAllowedNonAuth) {
    next("/dashboard/home");
    return;
  }

  if (requiresAuth && firebaseId && !hasUserData) {
    await doLoginUser(firebaseId);
  }

  const nowHasUserData =
    hasUserData || requiresAuth ? (await loginStatus()).hasUserData : false;

  if (requiresAuth && !nowHasUserData) {
    next("/login");
  } else {
    next();
  }
});

export default router;
