<template lang="pug">
    .billing-details-table.mb-12
        v-alert(v-if="error", type="error" outlined) {{error}}
        
        pre.debug {{status}}
        
        template(v-if="status && status === 'cancel'")
            v-alert(type="error") You have canceled your billing attempt

        template(v-if="!plan")
            v-card.no-billing-warning.pa-5(color="grey lighten-4" outlined)
                h2.font-weight-bold Your Brand Space is on the Early Access plan. 
                p No billing is currently due, and we will reach out in advance of future billing periods.
                v-btn(color="primary" large outlined @click="handleCheckoutRedirect") Manage Payment Method

        template(v-else)
            v-data-table(:loading="loading" :headers="headers", :items="items", :hide-default-footer="items.length < 9")
                template(v-slot:item.changePlan="{ item }")
                    v-btn(color="primary" outlined) Change Plan
            .d-flex.mt-10
              v-btn.mr-4(color="primary" large outlined @click="handleCheckoutRedirect") Manage Payment 
              div.smaller.align-self-center (redirects to Stripe checkout)
            
</template>

<script>
import { get, call } from "vuex-pathify";
import sumby from "lodash.sumby";
import { floatToCurrency } from "@/helpers.js";
export default {
  name: "BillingDetailsTable",
  data() {
    return {
      loading: false,
      status: null,
      error: "",
      headers: [
        {
          text: "Current Plan",
          value: "plan",
          align: "left"
        },
        {
          text: "Pricing",
          value: "pricing",
          align: "left"
        },
        {
          text: "# of Units",
          value: "unitCount",
          align: "left"
        },
        {
          text: "Total Monthly Cost",
          value: "cost",
          align: "left"
        },
        {
          text: "Discount",
          value: "discount",
          align: "left"
        },
        {
          text: "Next Amount Due",
          value: "due",
          align: "left"
        },
        {
          text: "Next Billing Date",
          value: "duedate",
          align: "left"
        } /*,
        {
          text: "",
          value: "changePlan",
          align: "left"
        }*/
      ]
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({ plan: "Billing/plan", connections: "Connections/connections" }),
    planPrice() {
      return this.plan.toLowerCase().indexOf("basic") > -1
        ? 5
        : this.plan.toLowerCase().indexOf("more") > -1
        ? 10
        : this.plan.toLowerCase().indexOf("future") > -1
        ? 20
        : 5;
    },
    items() {
      const duedate = "n/a";
      const unitCount = sumby(this.connections, "unitCount");
      let unitPrice = this.planPrice;
      let discount = unitPrice;
      const cost = floatToCurrency(unitCount * unitPrice);
      const due = floatToCurrency(unitPrice - discount);
      discount = "FREE EARLY ACCESS - " + cost;
      unitPrice = floatToCurrency(unitPrice);
      return [
        {
          plan: this.plan,
          pricing: `${unitPrice}/unit/month`,
          unitCount,
          cost,
          discount,
          due,
          duedate
        }
      ];
    }
  },
  methods: {
    ...call({
      getConnections: "Connections/getConnections",
      getStripeCheckoutRedirectLink: "Billing/getStripeCheckoutRedirectLink",
      openCheckout: "Billing/openCheckout"
    }),
    async handleCheckoutRedirect() {
      try {
        this.error = "";
        const checkoutId = await this.getStripeCheckoutRedirectLink();
        console.log("got checkout id", checkoutId);

        if (checkoutId) {
          this.openCheckout(checkoutId);
        } else {
          throw new Error(
            "Oops, there was an error taking you to Stripe for Checkout"
          );
        }
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },
    async init() {
      this.status = this.$route.query.status;
      this.loading = true;
      await this.getConnections();
      this.loading = false;
    }
  }
};
</script>
