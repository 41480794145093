<template lang="pug">
    .onboarding-wrapper
      .max-width
        v-container.px-md-12.py-md-12
            v-img.brand(src="@/assets/logos/logo_unitfinancials_500.png" max-width="400px")
            h1.mt-12 {{title}}
            h2.mt-8(v-if="subTitle") {{subTitle}}
            p.description(v-if="description" v-html="description").mt-4.mb-12
            slot
            .stages.d-flex.justify-center(v-if="stage && !hideStages", :style="{'margin-top': '5rem'}")
              v-icon(v-for="icon, idx in maxStages", :key="idx" small :color="calcColor(idx+1)", :class="{'mr-8': idx < maxStages - 1}" @click="setStage({newStage: idx})" :disabled="idx >= stage") mdi-circle
</template>

<script>
import { get, call } from "vuex-pathify";

export default {
  name: "OnboardingWrapper",
  props: {
    title: { type: String, required: true },
    subTitle: { type: String, default: null },
    description: { type: String, default: null },
    stage: { type: Number, default: 0 },
    maxStages: { type: Number, default: 0 }
  },

  data() {
    return {};
  },
  computed: {
    ...get({
      brandSpaceSubdomain: "Onboarding/registration@brandSpaceSubdomain",
      brandSpaceLabel: "Onboarding/registration@brandSpaceLabel"
    }),
    hideStages() {
      //if user was redirect from dashboard then Onboarding/registration should be empty
      return !this.brandSpaceSubdomain && !this.brandSpaceLabel;
    }
  },
  methods: {
    ...call({
      setStage: "Onboarding/setStage"
    }),
    calcColor(stageNum) {
      if (this.stage === stageNum) {
        return "black";
      } else {
        return "#ADADAD";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.max-width {
  max-width: 600px;
  margin: auto;
}
</style>
