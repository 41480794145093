import { make } from "vuex-pathify";
import { db } from "@/firebase.js";
import Firebase from "firebase/app";
import "firebase/auth";

const state = {
  connectionId: null,
  connections: [],
  syncIds: [],
  refreshEvery: 120000
};
const getters = {
  ...make.getters(state),
  prettyPrintProvider: () => provider => {
    if (provider === "QUICKBOOKS") return "QuickBooks Online";
    else if (provider === "FRESHBOOKS") return "FreshBooks";
    else if (provider === "XERO") return "Xero";
    else return provider;
  }
};

const mutations = make.mutations(state);

const actions = {
  getConnectionUrl: async ({ rootGetters }, { app }) => {
    const qboRedirectUri = Firebase.functions().httpsCallable("getQboAuthUri");
    const xeroRedirectUri = Firebase.functions().httpsCallable(
      "getXeroAuthUri"
    );

    let apiMethod;
    if (app === "QUICKBOOKS" || app === "QBO") apiMethod = qboRedirectUri;
    else if (app === "XERO") apiMethod = xeroRedirectUri;
    else
      throw new Error(
        "Could not start and get redirect uri to unknown app",
        app
      );

    const redirectUri = await apiMethod({
      state: JSON.stringify({
        conceptId:
          rootGetters["Onboarding/registration"].conceptId ||
          rootGetters["User/userData"].conceptId,
        invitationCode: rootGetters["Invitations/registration"].invitationCode,
        userId:
          rootGetters["Invitations/registration"].convertedUserId ||
          rootGetters["User/userData"].id,
        originHost: window.location.host
      })
    });
    return redirectUri.data;
  },

  updateUnitInfo: async (context, { connectionId, unitCount, unitNames }) => {
    const updateUnitInfo = Firebase.functions().httpsCallable("updateUnitInfo");

    return await updateUnitInfo({
      connectionId,
      unitCount: unitCount ? parseInt(unitCount) : null,
      unitNames
    });
  },

  getConnections: async ({ commit, rootGetters }) => {
    try {
      //await dispatch("ungetConnections", { snapshots: getters.snapshots });
      const getAccessibleConnectionsByUser = Firebase.functions().httpsCallable(
        "getAccessibleConnectionsByUser"
      );
      const res = await getAccessibleConnectionsByUser({
        userId: Firebase.auth().currentUser.uid,
        conceptId: rootGetters["User/userData"].conceptId
      });
      if (res && res.data) commit("SET_CONNECTIONS", res.data.connections);
    } catch (err) {
      console.error(err);
    }
  },

  bindSyncIds: async ({ commit }) => {
    return new Promise((resolve, reject) => {
      const query = db
        .collection("connections")
        .where("nowSyncing", "==", true);

      query.onSnapshot(
        async querySnapshot => {
          let syncIds = [];
          for (const doc of querySnapshot.docs) {
            syncIds.push(doc.id);
          }
          commit("SET_SYNC_IDS", syncIds);
          resolve();
        },
        err => {
          console.log(`Encountered error: ${err}`);
          reject(err);
        }
      );
    });
  },

  syncConnection: async (context, { connectionId, provider }) => {
    const syncConnection = Firebase.functions().httpsCallable("syncConnection");
    return await syncConnection({ connectionId, provider });
  },
  deleteConnection: async (context, { connectionId }) => {
    const deleteConnection = Firebase.functions().httpsCallable(
      "deleteConnection"
    );
    return await deleteConnection({
      userId: Firebase.auth().currentUser.uid,
      connectionId
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
