import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAHGzQH_bU4KkrMfHLY3KLLEeAPazZR9GI",
  authDomain: "zeewise-mvp.firebaseapp.com",
  projectId: "zeewise-mvp",
  storageBucket: "zeewise-mvp.appspot.com",
  messagingSenderId: "962818356486",
  appId: "1:962818356486:web:b987902697b8c25c6dda87",
  measurementId: "G-14B6J2K7T8"
};
firebase.initializeApp(firebaseConfig);

const initEmulators = async () => {
  console.log("initializing connections to emulator suite");
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.functions().useEmulator("localhost", 5001);
  firebase.firestore().useEmulator("localhost", 8089);
};

const isDev = process.env.NODE_ENV !== "production";
if (isDev) initEmulators();

// utils
const db = firebase.firestore();

const auth = firebase.auth();

// export utils/refs
export { db, auth };
