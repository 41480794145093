<template lang="pug">
    v-container.section-connection-list.pa-0

        .loading(v-if="loading")
          v-skeleton-loader(v-if="loading", type="card" v-for="n in 3", :key="n")
        
        .no-connections(v-else-if="!connections || connections.length < 1")
          v-alert(type="info")
            v-row
              v-col(cols="8") You have not yet added a Connection. Get started by connecting your accounting source or inviting units to connect.  
              v-col(cols="4")
                v-btn(to="/dashboard/connectionList" color="white" light depressed) + Connection

        .no-reports-to-show(v-else-if="!systemReportSeemsValid || !unitReportsSeemValid")
          v-alert(color="primary" outlined v-if="!loading")
            span We currently have no data available for
            b.mx-1 {{startDate | asDate}} ~ {{endDate | asDate}}.
            span A newly added Connection may take a few minutes to sync data.  
            .d-flex
              v-btn(color="primary" depressed @click="handleRefresh").mt-2 Refresh

        .reports-to-show(v-else)
          h2 System P&L
          v-row.mb-12
            v-col(cols="12" lg="6").d-flex.flex-column
              template(v-if="systemReport && systemReport.sumsAndAverages")
                ReportWrapper(:subtitle="brandSpaceLabel + ' Summary'", :tabs="[{value: 'unitAverages', text: 'Unit Average'}, {value: 'unitTotals', text: 'Unit Totals'}]", :activeTab="systemProfitAndLossCurrentTab", @change:tab="systemProfitAndLossCurrentTab = $event")
                  SystemReportForUnitTotals(:systemProfitAndLossCurrentTab="systemProfitAndLossCurrentTab")
              template(v-else)
                .error--text There is currently no data available for this date range.
            
            v-col(cols="12" lg="6").d-flex.flex-column
              ReportWrapper(:subtitle="brandSpaceLabel + ' Summary'" :tabs="[{value: 'unitAverages', text: 'Unit Average'}]")
                .mx-auto
                  DoughnutChart(v-if="systemReport && systemReport.sumsAndAverages && systemReport.sumsAndAverages.averages.asInt", :averages="systemReport.sumsAndAverages.averages.asInt")

            v-col(cols="12")
              h2 Unit P&L
              ReportWrapper(:subtitle="activeUnitName", :tabs="[{value: 'details', text: 'Details'}, {value: 'chart', text: 'Chart'}]", :activeTab="unitProfitAndLossCurrentTab", @change:tab="unitProfitAndLossCurrentTab = $event")
                UnitByUnitReport(:unitProfitAndLossCurrentTab="unitProfitAndLossCurrentTab", @change:unitName="activeUnitName = $event")
          
          v-row.mb-12
            v-col(cols="12")
              h2 Table
              ReportWrapper
                UnitByUnitReportTable.ma-n4
</template>

<script>
import { call, sync, get } from "vuex-pathify";
import DateRangeFilter from "@/components/Dashboard/Sections/Home/DateRangeFilter.vue";

import ReportWrapper from "@/components/Dashboard/Sections/Home/ReportWrapper.vue";
import UnitByUnitReportTable from "@/components/Dashboard/Sections/Home/Reports/UnitByUnitReportTable.vue";
import UnitByUnitReport from "@/components/Dashboard/Sections/Home/Reports/UnitByUnitReport.vue";
import SystemReportForUnitTotals from "@/components/Dashboard/Sections/Home/Reports/SystemReportForUnitTotals.vue";

import UnitReportChart from "@/components/Dashboard/Sections/Home/Reports/UnitReportChart.vue";
import DoughnutChart from "@/components/Dashboard/Sections/Home/Charts/DoughnutChart.vue";

export default {
  name: "Home",
  title: "Home Dashboard",
  mastheadComponent: DateRangeFilter,
  components: {
    DoughnutChart,
    UnitReportChart,
    DateRangeFilter,
    ReportWrapper,
    UnitByUnitReportTable,
    UnitByUnitReport,
    SystemReportForUnitTotals
  },
  filters: {
    asDate(dt) {
      const options = {
        // weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      };

      return dt.toLocaleDateString("en-Us", options);
    }
  },
  data() {
    return {
      systemProfitAndLossCurrentTab: "unitTotals",
      unitProfitAndLossCurrentTab: "details",
      activeUnitName: "Unit Connection..."
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      startDate: "Reports/startDate",
      endDate: "Reports/endDate",
      loading: "Reports/loading",
      connections: "Connections/connections",
      conceptId: "User/userData@conceptId",
      brandSpaceLabel: "System/brandSpaceData@brandSpaceLabel",
      systemReport: "Reports/reports@system",
      unitByUnitReports: "Reports/reports@unitByUnit"
    }),
    ...sync({ reportsCurrentlyOnScreen: "Reports/reportsCurrentlyOnScreen" }),
    systemReportSeemsValid() {
      if (
        !this.systemReport ||
        !this.systemReport.sumsAndAverages ||
        !this.systemReport.sumsAndAverages.sums ||
        !this.systemReport.sumsAndAverages.sums.asInt ||
        Object.entries(this.systemReport).length < 1
      )
        return false;

      const {
        totalIncome,
        cogs,
        netIncome,
        grossProfit,
        expenses,
        netOther
      } = this.systemReport.sumsAndAverages.sums.asString;

      if (
        !totalIncome &&
        !cogs &&
        !netIncome &&
        !grossProfit &&
        !expenses &&
        !netOther
      )
        return false;

      return this.systemReport && this.systemReport.sumsAndAverages;
    },
    unitReportsSeemValid() {
      return (
        this.unitByUnitReports &&
        Object.entries(this.unitByUnitReports).length > 0
      );
    }
  },
  methods: {
    ...call({
      updateAllReportsCurrentlyOnScreen:
        "Reports/updateAllReportsCurrentlyOnScreen"
    }),
    handleRefresh() {
      this.init();
    },
    async init() {
      this.reportsCurrentlyOnScreen = [
        { name: "UnitByUnitReport", action: "getUnitByUnitParsedReportData" },
        { name: "system", action: "getSystemParsedReportData" }
      ];
      await this.updateAllReportsCurrentlyOnScreen();
    }
  }
};
</script>
