<template lang="pug">
    .unit-report-chart.mt-6
        #unitReportChartContainer
            v-skeleton-loader(v-if="loading", type="image")

</template>

<script>
import { waitForElemToExist } from "@/helpers";
import Highcharts from "highcharts";

import { get } from "vuex-pathify";

export default {
  name: "UnitReportChart",
  props: {
    unitName: { type: String, default: null },
    unitReportData: { type: Object, default: null }
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...get({ systemReportFields: "Reports/systemReportFields" }),
    series() {
      return [
        {
          name: this.unitName,
          data: [
            {
              name: "Revenue",
              y:
                this.unitReportData.totalIncome &&
                !isNaN(this.unitReportData.totalIncome.fltValue)
                  ? this.unitReportData.totalIncome.fltValue
                  : 0,
              color: this.systemReportFields.find(
                itm => itm.key === "netIncome"
              ).color
            },
            {
              name: "COGS",
              y:
                this.unitReportData.cogs &&
                !isNaN(this.unitReportData.cogs.fltValue)
                  ? this.unitReportData.cogs.fltValue
                  : 0,
              color: this.systemReportFields.find(itm => itm.key === "cogs")
                .color
            },
            {
              name: "Expenses",
              y:
                this.unitReportData.expenses &&
                !isNaN(this.unitReportData.expenses.fltValue)
                  ? this.unitReportData.expenses.fltValue
                  : 0,
              color: this.systemReportFields.find(itm => itm.key === "expenses")
                .color
            }
          ]
        }
      ];
    }
  },
  created() {
    window.Highcharts = Highcharts;
  },
  mounted() {
    // Sometimes when switching between tabs highcharts fails to load into the container on mounted() for some reason
    setTimeout(() => {
      this.initChart();
    }, 100);
  },
  beforeDestroy() {
    if (this.chart) this.chart.destroy();
  },
  methods: {
    async initChart() {
      if (this.chart) this.chart.destroy();
      this.chart = null;

      await waitForElemToExist("#unitReportChartContainer");

      this.chart = new Highcharts.chart("unitReportChartContainer", {
        title: {
          text: ""
        },
        chart: { type: "bar" },
        credits: false,
        events: {
          load: () => (this.loading = false)
        },
        xAxis: {
          categories: ["Revenue", "COGS", "Expenses"],
          labels: {
            enabled: true
          }
        },
        yAxis: {
          labels: {
            enabled: true
          }
        },
        tooltip: { valuePrefix: "$" }, // valueSuffix: " %", pointFormat: "{series.name}" /* for empty data */
        series: this.series,
        legend: false
      });
    }
  }
};
</script>
