<template lang="pug">
    .unit-drill-down-items
      v-row.font-weight-bold
        v-col(cols="7")
          .label {{headerItem.name}}
        v-col(cols="5").text-right
          .val {{headerItem.data | displayValue(numDecimals)}}
      
      v-row(v-for="itm in topItems", :key="itm.name" )
        v-col(cols="7")
          .label  {{itm.name}}
        v-col(cols="5").text-right
          .val {{itm | displayValue(numDecimals)}}
</template>

<script>
import { floatToCurrency } from "@/helpers.js";
export default {
  name: "UnitDrillDownItems",
  data() {
    return { numDecimals: 0 };
  },
  filters: {
    displayValue: (val, numDec) =>
      floatToCurrency(val.fltValue, numDec, val.currency)
  },
  props: {
    headerItem: { type: Object, required: true },
    topItems: { type: Array, default: () => [] }
  }
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 0.75rem;
  color: #4f4f4f;
}

.val {
  font-size: 0.75rem;
}
</style>
