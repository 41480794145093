<template lang="pug">
    .section-profile-page
        ModalEditAccountDetails(@click:save="handleSave")
        ModalEnterPassword(:error="passwordError", @click="gotPassword = $event", :key="attempt")
        ModalEditPassword

        h2.mb-6 Account Details
        
        v-card(max-width="500" flat)
            BasicDetails(readOnly showPseudoPassword)
            .d-flex.mt-6
                v-btn(color="accent" outlined @click="showModalEditAccountDetails = true").mr-3
                  v-icon.mr-2(small) mdi-account-edit
                  | Click to Edit Info
                v-btn(color="accent" outlined @click="showModalEditPassword = true")
                  v-icon.mr-2(small) mdi-lock
                  | Click to Change Password
        
        h2.mt-16.mb-6 Brand Space Details

        BrandSpaceMetadataForm.ma-12(@change:loading="loading = $event")
        


</template>

<script>
import BasicDetails from "@/components/Dashboard/Sections/Profile/BasicDetails.vue";
import ModalEditAccountDetails from "@/components/Dashboard/Sections/Profile/ModalEditAccountDetails.vue";
import ModalEnterPassword from "@/components/Dashboard/Sections/Profile/ModalEnterPassword.vue";
import ModalEditPassword from "@/components/Dashboard/Sections/Profile/ModalEditPassword.vue";
import BrandSpaceMetadataForm from "@/components/Dashboard/Sections/BrandSpace/BrandSpaceMetadataForm.vue";
import { get, sync, call } from "vuex-pathify";
export default {
  name: "Profile",
  title: "Profile",
  components: {
    BasicDetails,
    ModalEditAccountDetails,
    ModalEnterPassword,
    ModalEditPassword,
    BrandSpaceMetadataForm
  },
  watch: {
    async gotPassword(newVal) {
      if (newVal) {
        const saveRes = await this.saveUserData({
          ...this.dataToSave,
          password: newVal
        });

        if (saveRes === true) {
          this.gotPassword = null;
          this.showModalEnterPassword = false;
        } else {
          this.passwordError = "Are you sure that is your correct password?";
        }
      }
    }
  },
  data() {
    return {
      loading: true,
      attempt: 0,
      gotPassword: false,
      passwordError: null,
      dataToSave: {}
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({ userData: "User/userData" }),
    ...sync({
      showModalEditAccountDetails: "Nav/modals@showModalEditAccountDetails",
      showModalEnterPassword: "Nav/modals@showModalEnterPassword",
      showModalEditPassword: "Nav/modals@showModalEditPassword"
    })
  },
  methods: {
    ...call({ saveUserData: "User/saveUserData" }),
    async handleSave(e) {
      this.passwordError = null;
      this.attempt++;
      this.showModalEditAccountDetails = false;
      const { firstName, lastName, email } = e;

      this.dataToSave = { firstName, lastName, email };

      if (email !== this.userData.email) {
        this.showModalEnterPassword = true;

        // Set a watch condition to call this.saveUserData only after user has entered their password
      } else {
        await this.saveUserData({ firstName, lastName });
      }
    },
    init() {
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.max-width {
  max-width: 500px;
}
</style>
