import Firebase from "firebase/app";
import { make } from "vuex-pathify";

const state = {
  brandSpaceData: {
    conceptId: "",
    brandSpaceSubdomain: "",
    brandSpaceLabel: "",
    subdomainActivated: false
  },
  brandSpaceMetadata: {
    typeOfBusiness: "",
    unitLabel: "",
    unitLabelOther: "",
    revenueLabel: "",
    inventoryTracking: "",
    unitsActive: 0,
    unitsPending: 0,
    unitsCorpActive: 0,
    unitsCorpPending: 0,
    ownersActive: 0,
    ownersPending: 0
  },
  systems: []
};
const getters = {
  ...make.getters(state)
};

const mutations = make.mutations(state);

const actions = {
  brandSpaceIsAvailable: async (context, { brandSpaceSubdomain }) => {
    const brandSpaceExists = Firebase.functions().httpsCallable(
      "brandSpaceExists"
    );
    const res = await brandSpaceExists({ brandSpaceSubdomain });
    const { exists } = res.data;
    return !exists;
  },

  registerBrandSpace: async (
    context,
    { email, brandSpaceSubdomain, brandSpaceLabel }
  ) => {
    const payload = {
      brandSpaceSubdomain,
      brandSpaceLabel
    };
    try {
      const createBrandSpace = Firebase.functions().httpsCallable(
        "createBrandSpace"
      );
      const res = await createBrandSpace({ email, system: payload });
      return res.data && res.data.conceptId ? res.data.conceptId : null;
    } catch (err) {
      console.error(
        "Failed to create system: ",
        payload,
        " for email: ",
        email
      );
      return null;
    }
  },

  loadBrandSpaceData: async ({ commit, getters, dispatch }, conceptId) => {
    const getBrandSpaceData = Firebase.functions().httpsCallable(
      "getBrandSpaceData"
    );
    const res = await getBrandSpaceData({ conceptId });
    if (res && res.data && res.data.brandSpaceData) {
      const serverData = res.data.brandSpaceData;

      commit("SET_BRAND_SPACE_DATA", {
        ...getters.brandSpaceData,
        conceptId: serverData.conceptId,
        brandSpaceLabel: serverData.brandSpaceLabel,
        brandSpaceSubdomain: serverData.brandSpaceSubdomain,
        subdomainActivated: serverData.subdomainActivated
      });

      dispatch(
        "Billing/setBillingPlan",
        {
          plan: serverData.billingPlan
        },
        {
          root: true
        }
      );
      return true;
    } else {
      return false;
    }
  },

  loadBrandSpaceMetadata: async ({ commit }, conceptId) => {
    const getBrandSpaceMetadata = Firebase.functions().httpsCallable(
      "getBrandSpaceMetadata"
    );
    const res = await getBrandSpaceMetadata({ conceptId });
    if (res && res.data && res.data.brandSpaceMetadata) {
      const serverData = res.data.brandSpaceMetadata;

      commit("SET_BRAND_SPACE_METADATA", serverData);
      return true;
    } else {
      return false;
    }
  },

  updateBrandSpaceMetadata: async (
    context,
    { conceptId, brandSpaceMetadata }
  ) => {
    const updateBrandSpaceMetadata = Firebase.functions().httpsCallable(
      "updateBrandSpaceMetadata"
    );

    return await updateBrandSpaceMetadata({ conceptId, brandSpaceMetadata });
  },

  getSystems: async ({ commit }) => {
    const getSystems = Firebase.functions().httpsCallable("getSystems");
    const res = await getSystems();
    if (res && res.data && res.data.systems) {
      const serverData = res.data.systems;
      commit("SET_SYSTEMS", serverData);
      return true;
    } else {
      return false;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
