import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

const vuetifyOpts = {
  rtl: false,
  theme: {
    // dark: true,
    themes: {
      light: {
        sectionHeaderColor: "#4f5a6c",
        primary: "#4040db",
        textDark: "#00002E",
        accent: "#4040DB",
        secondary: "#2E43E5",
        success: "#6FCF97",
        info: "#2196F3",
        warning: "#FFB429",
        error: "#FF99A1",
        red: "#EB5757"
      }
    }
  }
};

Vue.use(Vuetify);

export default new Vuetify(vuetifyOpts);
