<template lang="pug">
    .dashboard-section-wrapper
        v-overlay(:value="loading")
          v-progress-circular(indeterminate size="64")
        .d-flex.justify-space-between.align-center
          h2 {{subtitle}}
          slot
       
</template>

<script>
export default {
  name: "DashboardSectionWrapper",
  props: {
    loading: { type: Boolean, default: false },
    subtitle: { type: String, default: "" }
  }
};
</script>
