<template lang="pug">
    .create-system
      OnboardingWrapper(:title="title", :subTitle="header.subTitle", :description="header.description", :stage="header.stage" :maxStages="header.maxStages")
        v-overlay(:value="pageIsLoading")
          v-progress-circular(indeterminate size="64")
        
        v-dialog(:persistent="invitationCodeReadOnly" :value="invitationHasExpired" max-width="600")
          v-card.pa-6
            h1.mb-6 Invalid or expired invite code
            p If you previously used this invite, no further action is required. If not, please ask the {{brandSpaceDisplay}} Brand Space admin to resend your invite email.
            p.text-right 
              a.nav-link.hoverable(v-show="invitationCodeReadOnly" href="/onboarding/InviteeJoin") enter a different invite code
        
        InvitationCodeInput.mb-6(v-on:validated="handleValidatedEvent($event)" v-on:expired="handleExpiredEvent($event)" )
        WorkEmailAddress.mb-6(@submit="handleClick" :readOnly="!invitationCodeValidated")
        NameInput.mb-6(:disabled="!invitationCodeValidated")

        .errors
          .error-alert.email-not-available(v-if="errors.emailNotAvailable")
            p 
              span.error.pa-1.mr-1 {{errors.unavailableEmail}}
              |  is not available. It may already be in use by someone else. Please try another email address.
          .error-alert.couldNotUpdateInvite(v-if="errors.couldNotUpdateInvite")
            p 
              span.red--text We're having trouble updating your info. Please check for the most recent invite email link or ask your Brand Space Admin to resend.

        BtnNext(:disabled="!allInputsAreValid", :loading="loading", @click="handleClick")

        div.text-center.mt-6
          a.nav-link.smaller.hoverable(href="/login") already have a user? login here
</template>

<script>
import OnboardingWrapper from "@/components/Onboarding/OnboardingWrapper.vue";
import { get, sync, call } from "vuex-pathify";
import InvitationCodeInput from "@/components/Onboarding/Invitees/InvitationCodeInput.vue";
import WorkEmailAddress from "@/components/Onboarding/System/WorkEmailAddress.vue";
import NameInput from "@/components/Onboarding/Invitees/NameInput.vue";
import BtnNext from "@/components/Global/BtnNext.vue";
export default {
  name: "InviteeJoin",
  components: {
    OnboardingWrapper,
    BtnNext,
    InvitationCodeInput,
    WorkEmailAddress,
    NameInput
  },
  data() {
    return {
      header: {
        subTitle: " ",
        description: "Please confirm your information below.",
        stage: 1,
        maxStages: 3
      },
      pageIsLoading: false,
      invitationCodeReadOnly: false,
      invitationHasExpired: false,
      loading: false,
      errors: {
        couldNotUpdateInvite: false
      }
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      invitationCodeValidated:
        "Invitations/registration@invitationCodeValidated",
      invitationCode: "Invitations/registration@invitationCode",
      brandSpaceLabel: "System/brandSpaceData@brandSpaceLabel"
    }),
    ...sync({
      conceptId: "Onboarding/registration@conceptId",
      email: "Onboarding/registration@email",
      firstName: "Onboarding/registration@firstName",
      lastName: "Onboarding/registration@lastName",
      convertedUserId: "Invitations/registration@convertedUserId",
      inviteeClickedToOpenEmail: "Invitations/events@inviteeClickedToOpenEmail"
    }),
    allInputsAreValid() {
      return (
        this.email &&
        this.conceptId &&
        this.firstName &&
        this.lastName &&
        this.invitationCodeValidated
      );
    },
    brandSpaceDisplay() {
      return this.brandSpaceLabel || "";
    },
    title() {
      return `Join the ${this.brandSpaceDisplay} Brand Space`;
    }
  },
  methods: {
    trimUserInputs() {
      this.email = this.email.trim();
      this.conceptId = this.conceptId.trim();
      this.firstName = this.firstName.trim();
      this.lastName = this.lastName.trim();
    },
    ...call({
      updateInviteDetails: "Invitations/updateInviteDetails",
      emailIsAvailable: "User/emailIsAvailable",
      setActiveItem: "Onboarding/setActiveItem",
      loadBrandSpaceData: "System/loadBrandSpaceData"
    }),
    async handleExpiredEvent() {
      this.invitationHasExpired = true;
    },
    async handleValidatedEvent(inviteData) {
      if (this.invitationCodeValidated) {
        this.conceptId = inviteData.conceptId;
        this.email = inviteData.email;
        this.firstName = inviteData.firstName;
        this.lastName = inviteData.lastName;
        this.inviteeClickedToOpenEmail = true;
        this.loadBrandSpaceData(this.conceptId);
      }
    },
    async handleClick() {
      this.loading = true;
      this.errors.emailNotAvailable = false;
      this.errors.couldNotUpdateInvite = false;
      this.trimUserInputs();
      if (this.allInputsAreValid) {
        const emailIsAvailable = await this.emailIsAvailable({
          userId: this.convertedUserId,
          email: this.email,
          invitationCode: this.invitationCode
        });
        if (!emailIsAvailable) {
          this.errors.emailNotAvailable = true;
          this.errors.unavailableEmail = this.email;
          this.loading = false;
        } else {
          try {
            const updatedSuccesfully = await this.updateInviteDetails();
            if (!updatedSuccesfully) {
              throw new Error("Could not update this invitee's info");
            } else {
              this.setActiveItem({ to: "ConnectUnit" });
            }
          } catch (err) {
            this.errors.couldNotUpdateInvite = true;
            console.error(err);
          } finally {
            this.loading = false;
          }
        }
      }
    },
    init() {
      this.conceptId = "";
      this.invitationCodeReadOnly =
        this.$route.query && this.$route.query.invitationCode ? true : false;
    }
  }
};
</script>
