<template lang="pug">
    .section-connection-list
        ModalConnectDataSource
        DashboardSectionWrapper(:subtitle="$options.subtitle" :loading="loading")
        ConnectionTable(@change:loading="loading = $event")
</template>

<script>
import { get } from "vuex-pathify";
import DashboardSectionWrapper from "@/components/Dashboard/Sections/DashboardSectionWrapper.vue";
import ConnectionTable from "@/components/Dashboard/Sections/Connections/ConnectionsTable.vue";
import ModalConnectDataSource from "@/components/Dashboard/Sections/Connections/ModalConnectDataSource.vue";
export default {
  name: "ConnectionList",
  title: "Data Connections",
  subtitle: "Connection List",
  mastheadButton: {
    text: "+ Connect New Data Source",
    modal: "CONNECT_NEW_DATA_SOURCE"
  },
  components: {
    DashboardSectionWrapper,
    ConnectionTable,
    ModalConnectDataSource
  },
  data() {
    return { loading: true };
  },
  computed: {
    ...get({
      showModalConnectNewDataSource: "Nav/modals@showModalConnectNewDataSource"
    })
  }
};
</script>
