<template lang="pug">
    .create-system
      OnboardingWrapper(:title="header.title", :subTitle="header.subTitle", :description="header.description", :stage="header.stage" :maxStages="header.maxStages")
        v-form(ref="form" v-model="isFormValid")
          WorkEmailAddress.mb-6(@submit="handleClick")
          SystemNameInput.mb-6(@submit="handleClick")
          
          .errors
            .error-alert.email-not-available(v-if="errors.emailNotAvailable")
              p 
                span.error.pa-1.mr-1 {{errors.unavailableEmail}}
                |  is not available. It may  be in use by someone else. Please try another email address.
            .error-alert.brandspace-not-available(v-if="errors.brandSpaceNotAvailable")
              p <span class='error pa-1'><strong>{{brandSpaceSubdomain}}</strong>.{{hostname}}</span> is already in use.
              p Try a variation such as: 
                a(@click="changeToAlternate(1)").success.pa-1.mx-1 {{alternateBrandSpace}} 
                | or
                a(@click="changeToAlternate(2)").success.pa-1.mx-1 {{alternateBrandSpace2}}
              p.mt-4.text-right.smaller <a href="/login" class="nav-link">trying to login?</a>
          BtnNext(:disabled="disableNext", :loading="loading", @click="handleClick")
</template>

<script>
import { randomChars } from "@/helpers.js";
import OnboardingWrapper from "@/components/Onboarding/OnboardingWrapper.vue";
import { get, sync, call } from "vuex-pathify";
import WorkEmailAddress from "@/components/Onboarding/System/WorkEmailAddress.vue";
import SystemNameInput from "@/components/Onboarding/System/SystemNameInput.vue";
import BtnNext from "@/components/Global/BtnNext.vue";
export default {
  name: "CreateSystem",
  components: { OnboardingWrapper, BtnNext, WorkEmailAddress, SystemNameInput },
  watch: {
    brandSpaceSubdomain() {
      this.errors.brandSpaceNotAvailable = false;
    }
  },
  data() {
    return {
      header: {
        title: "Create Your Brand Space",
        subTitle: "",
        description: "Just a few easy steps",
        stage: 1,
        maxStages: 3
      },
      loading: false,
      errors: {
        brandSpaceNotAvailable: false,
        emailExists: false
      },
      isFormValid: false
    };
  },
  computed: {
    ...get({
      hostname: "hostname",
      email: "Onboarding/registration@email"
    }),
    ...sync({
      brandSpaceSubdomain: "Onboarding/registration@brandSpaceSubdomain"
    }),
    alternateBrandSpace() {
      const currentYear = new Date().getFullYear().toString();
      const brandSpaceSubdomainNoYear = this.brandSpaceSubdomain.replace(
        currentYear.toString(),
        ""
      );
      return this.brandSpaceSubdomain.indexOf(currentYear) < 0
        ? this.brandSpaceSubdomain + "-official"
        : brandSpaceSubdomainNoYear + "-official" + currentYear;
    },
    alternateBrandSpace2() {
      const currentYear = new Date().getFullYear().toString();
      const brandSpaceSubdomainNoYear = this.brandSpaceSubdomain.replace(
        currentYear,
        ""
      );
      return this.brandSpaceSubdomain.indexOf(currentYear) < 0
        ? this.brandSpaceSubdomain + currentYear
        : this.brandSpaceSubdomain.indexOf("-" + currentYear) < 0
        ? brandSpaceSubdomainNoYear + "-" + currentYear
        : brandSpaceSubdomainNoYear + randomChars(6);
    },
    disableNext() {
      return (
        !this.isFormValid ||
        !this.email ||
        !this.brandSpaceSubdomain ||
        !this.brandSpaceIsAvailable
      );
    }
  },
  methods: {
    ...call({
      brandSpaceIsAvailable: "System/brandSpaceIsAvailable",
      emailIsAvailable: "User/emailIsAvailable"
    }),
    async handleClick() {
      this.errors.emailNotAvailable = false;
      this.errors.brandSpaceNotAvailable = false;
      if (!this.disableNext) {
        this.loading = true;
        try {
          const brandSpaceIsAvailable = await this.brandSpaceIsAvailable({
            brandSpaceSubdomain: this.brandSpaceSubdomain
          });

          const emailIsAvailable = await this.emailIsAvailable({
            userId: 0,
            email: this.email
          });
          if (!emailIsAvailable) {
            this.errors.emailNotAvailable = true;
            this.errors.unavailableEmail = this.email;
          } else {
            if (brandSpaceIsAvailable) {
              this.$emit("click", "CreateSystemAccount");
            } else {
              this.errors.brandSpaceNotAvailable = true;
            }
          }
        } catch (err) {
          console.error(err);
        } finally {
          this.loading = false;
        }
      }
    },
    changeToAlternate(num) {
      this.brandSpaceSubdomain =
        num == 2
          ? this.alternateBrandSpace2.split(".")[0]
          : this.alternateBrandSpace.split(".")[0];
      this.errors.brandSpaceNotAvailable = false;
    }
  }
};
</script>
