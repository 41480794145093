<template lang="pug">
    .cogs-report-table
      .d-flex.align-center.justify-end
          v-btn-toggle(:value="rangeValue" mandatory activeClass="active-range-button" @change="handleChange")
            v-btn.btn Year
            v-btn.btn Month
          PopupDatePicker.ml-4
</template>

<script>
import PopupDatePicker from "@/components/Dashboard/Sections/Home/PopupDatePicker.vue";
import { sync, call } from "vuex-pathify";
// import sumby from "lodash.sumby";
export default {
  name: "DateRangeFilter",
  components: { PopupDatePicker },
  data() {
    return {};
  },
  computed: {
    ...sync({
      rangeType: "Reports/rangeType",
      startDate: "Reports/startDate",
      endDate: "Reports/endDate"
    }),
    rangeValue() {
      return this.rangeType === "YEAR" ? 0 : 1;
    }
  },
  methods: {
    ...call({
      updateAllReportsCurrentlyOnScreen:
        "Reports/updateAllReportsCurrentlyOnScreen"
    }),
    handleChange(e) {
      const initialRangeType = this.rangeType;
      const newRangeType = e === 0 ? "YEAR" : "MONTH";
      const today = new Date();
      if (initialRangeType === "YEAR" && newRangeType === "MONTH") {
        // reset start and end date for reports
        this.startDate = new Date(today.getFullYear(), 0, 1, 0, 0);
        this.endDate = new Date(today.getFullYear(), 0, 31, 23, 59);
      } else if (initialRangeType === "MONTH" && newRangeType === "YEAR") {
        this.startDate = new Date(today.getFullYear(), 0, 1, 0, 0);
        this.endDate = new Date(today.getFullYear(), 11, 31, 23, 59);
      }
      this.rangeType = newRangeType;

      this.updateAllReportsCurrentlyOnScreen();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.btn {
  background: #d9dbdf;
  &:before {
    opacity: 1;
    background: #d9dbdf;
  }
}
.btn.active-range-button {
  &:before {
    opacity: 1;
    color: $accent;
    background: $accent;
  }
  ::v-deep span.v-btn__content {
    color: white;
    font-weight: bold;
  }
}
</style>
