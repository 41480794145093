<template lang="pug">
  #main(:class="{debug: isDev}")
    v-app
      v-main
        router-view

</template>

<script>
import { call } from "vuex-pathify";
export default {
  name: "App",
  components: {},
  data: () => ({
    //
  }),
  computed: {
    isDev() {
      return window && !!window.webpackHotUpdate;
    }
  },
  methods: {
    ...call({})
  }
};
</script>

<style lang="scss">
@import "@/assets/css/colors.scss";
@import "@/assets/css/typography.scss";

.contain-width {
  max-width: 600px;
  margin: auto;
}

pre.debug {
  display: none;
}
.debug pre.debug {
  display: inherit;
}
</style>
