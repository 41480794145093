<template lang="pug">
    .unit-names
        p Tell us the name{{countPluralSuffix}} of the {{countAsWords}} you just connected.
        v-virtual-scroll(v-if="unitNames && unitNames.length > 0", :height="scrollerMaxHeight" :item-height="scrollerItemHeight", :items="unitNames", :bench="unitCount")
          template(v-slot:default="{ item, index }")
            UnitNameInput(:index="index+1", :key="index", :defaultName="unitNames[index]", @change="$set(unitNames, index, $event)")
        BtnNext.mt-4(:loading="loading" @click="handleClickNext")
        BtnPrevious.mt-6(:loading="loading" @click="handleClickPrevious")
</template>

<script>
import UnitNameInput from "@/components/Dashboard/Sections/Connections/UnitNameInput.vue";
import { call } from "vuex-pathify";
import BtnPrevious from "@/components/Global/BtnPrevious.vue";
import BtnNext from "@/components/Global/BtnNext.vue";
export default {
  name: "UnitNames",
  components: { UnitNameInput, BtnPrevious, BtnNext },
  props: {
    type: { type: String, required: true },
    connectionId: { type: String, required: true },
    provider: { type: String, required: true },
    unitCount: { type: Number, default: -1 },
    defaultUnitName: { type: String, required: false }
  },
  watch: {
    unitCount() {
      this.init();
    }
  },
  data() {
    return {
      unitNames: [""],
      loading: false,
      scrollerItemHeight: 66,
      minimumScrollerHeight: 70,
      maximumScrollerHeight: 330
    };
  },
  created() {
    this.init();
  },
  computed: {
    scrollerMaxHeight() {
      const height = this.unitCount * this.scrollerItemHeight;
      return height < this.minimumScrollerHeight
        ? this.minimumScrollerHeight
        : height > this.maximumScrollerHeight
        ? this.maximumScrollerHeight
        : height;
    },
    countPluralSuffix() {
      return !this.unitCount || this.unitCount === 1 ? "" : "s";
    },
    countAsWords() {
      if (!this.unitCount) return "?";
      const numbers = [
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
        "twenty"
      ];
      if (this.unitCount > 20) return this.unitCount.toString();
      else
        return (
          numbers[this.unitCount - 1] +
          " unit" +
          (this.unitCount == 1 ? "" : "s")
        );
    },
    allUnitsHaveBeenNamed() {
      const namedUnits = this.unitNames.filter(itm => itm && itm.length > 1);
      return namedUnits.length === this.unitCount;
    },
    propData() {
      return {
        type: this.type,
        connectionId: this.connectionId,
        provider: this.provider,
        unitCount: this.unitCount,
        unitNames: this.unitNames,
        defaultUnitName: this.defaultUnitName
      };
    }
  },
  methods: {
    ...call({
      updateUnitInfo: "Connections/updateUnitInfo",
      setActiveItem: "Onboarding/setActiveItem"
    }),
    async handleClickNext() {
      this.loading = true;
      if (this.allUnitsHaveBeenNamed) {
        await this.updateUnitInfo(this.propData);
        this.navigateNext();
      }
      this.loading = false;
    },
    async handleClickPrevious() {
      this.loading = true;
      this.navigatePrevious();
      this.loading = false;
    },
    navigateNext() {
      if (this.type === "ONBOARDING") {
        this.setActiveItem({ to: "ConnectionComplete" });
      } else {
        this.$emit("nav", {
          to: "FinishedConnecting"
        });
      }
    },
    navigatePrevious() {
      if (this.type === "ONBOARDING") {
        this.setActiveItem({
          to: "ConnectionInfoOne",
          data: this.propData
        });
      } else {
        this.$emit("nav", {
          to: "UnitCount",
          data: this.propData
        });
      }
    },
    init() {
      let len = !this.unitCount || this.unitCount < 1 ? 1 : this.unitCount;
      this.unitNames = Array(len)
        .join(".")
        .split(".");
      this.unitNames[0] = this.defaultUnitName
        ? decodeURIComponent(this.defaultUnitName)
        : "";
    }
  }
};
</script>
