// import router from '@/router'
import Firebase from "firebase/app";
import "firebase/auth";
import { db } from "@/firebase.js";
import { make } from "vuex-pathify";
import { isValidEmail } from "@/helpers";

const state = {
  userData: null,
  viewableUsers: []
};
const getters = {
  ...make.getters(state)
};

const mutations = make.mutations(state);

const actions = {
  register: async (
    { dispatch },
    { type, firstName, lastName, email, password, conceptId }
  ) => {
    const payload = {
      conceptId,
      type,
      firstName,
      lastName,
      email,
      emailVerified: false,
      password
    };

    const createUser = Firebase.functions().httpsCallable("createUser");
    const res = await createUser(payload);
    const idOfCreatedUser =
      res.data && res.data.result ? res.data.result : null;
    if (idOfCreatedUser) {
      await Firebase.auth().signInWithEmailAndPassword(email, password);

      await dispatch("bindUserData", { id: idOfCreatedUser });
      return true;
    } else
      console.error(
        "Failed to create user correctly for some reason, for email",
        email
      );
    return false;
  },

  signIn: async ({ dispatch }, { email, password }) => {
    try {
      if (!email || !password) return false;
      const signInRes = await Firebase.auth().signInWithEmailAndPassword(
        email,
        password
      );
      if (signInRes && signInRes.user && signInRes.user.uid) {
        await dispatch("bindUserData", { id: signInRes.user.uid });

        return signInRes;
      } else {
        throw new Error({
          message: "Unknown error while logging you in"
        });
      }
    } catch (err) {
      console.error(err);
      return err ? { error: err.message } : { error: "Unknown Error" };
    }
  },

  emailIsAvailable: async (context, { userId, email, invitationCode }) => {
    const availableUserEmail = Firebase.functions().httpsCallable(
      "availableUserEmail"
    );

    const res = await availableUserEmail({ userId, email, invitationCode });
    const { available } = res.data;
    return available;
  },

  saveUserData: async ({ dispatch }, payload) => {
    try {
      const { firstName, lastName, email, password } = payload;

      const data = {};
      if (firstName) data.firstName = firstName;
      if (lastName) data.lastName = lastName;
      if (email && isValidEmail(email) && password) data.email = email;

      if (email) {
        const oldEmail = Firebase.auth().currentUser.email;
        // also update email for firebase user
        const saveWithPasswordRes = await Firebase.auth()
          .signInWithEmailAndPassword(oldEmail, password)
          .then(function(userCredential) {
            userCredential.user.updateEmail(email);
            return true;
          })
          .catch(() => false);

        if (!saveWithPasswordRes) throw new Error("Invalid password");
      }

      if (Object.entries(payload).length !== 0) {
        const userId = Firebase.auth().currentUser.uid;
        db.collection("users")
          .doc(userId)
          .update(payload);
      }

      await dispatch("bindUserData");
      return true;
    } catch (err) {
      console.error("SaveWithPassErr:", err);
      return false;
    }
  },
  changePassword: async (context, { oldPassword, newPassword }) => {
    try {
      if (!oldPassword || !newPassword) return false;

      return await Firebase.auth()
        .signInWithEmailAndPassword(
          Firebase.auth().currentUser.email,
          oldPassword
        )
        .then(function(userCredential) {
          userCredential.user.updatePassword(newPassword);
          return true;
        })
        .catch(() => false);
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  logout: async ({ commit }) => {
    await Firebase.auth().signOut();
    commit("SET_USER_DATA", null);
  },
  bindUserData: async ({ commit, dispatch }, payload) => {
    try {
      const id = payload && payload.id ? payload.id : null;

      if (!Firebase.auth().currentUser || !Firebase.auth().currentUser.uid)
        return false;

      let firebaseUserId = id || Firebase.auth().currentUser.uid;

      const res = await new Promise((resolve, reject) => {
        const query = db.collection("users").doc(firebaseUserId);

        query.onSnapshot(
          querySnapshot => {
            const data = querySnapshot.data();
            if (data && data.email) {
              commit("SET_USER_DATA", { ...data, id: firebaseUserId });
              resolve(data);
            } else {
              throw new Error("Could not bind user info for some reason");
            }
          },
          err => {
            if (err && err.code && err.code !== "permission-denied") {
              console.error(`Encountered error: ${err}`);
            }
            reject(err);
          }
        );
      });

      if (res && res.conceptId) {
        await dispatch("System/loadBrandSpaceData", res.conceptId, {
          root: true
        });
      }
    } catch (err) {
      console.error(err);
    }
  },

  getViewableUsers: async ({ commit }) => {
    try {
      const getUserViewableUsers = Firebase.functions().httpsCallable(
        "getUserViewableUsers"
      );
      const res = await getUserViewableUsers({
        userId: Firebase.auth().currentUser.uid
      });
      if (res && res.data) commit("SET_VIEWABLE_USERS", res.data.viewable);
    } catch (err) {
      console.error(err);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
