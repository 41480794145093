var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invites-table"},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"hide-default-footer":_vm.items.length < 9},scopedSlots:_vm._u([{key:"item.opened",fn:function(ref){
var item = ref.item;
return [(item.opened === true)?[_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-check-circle")])]:_c('span',[_vm._v(_vm._s(item.opened))])]}},{key:"item.completed",fn:function(ref){
var item = ref.item;
return [(item.completed === true)?[_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-check-circle")])]:_c('span',[_vm._v(_vm._s(item.completed))])]}},{key:"item.sendReminder",fn:function(ref){
var item = ref.item;
return [(item.completed !== true)?_c('v-btn',{attrs:{"text":"","small":"","color":"accent","disabled":_vm.disabledReminderButtons.includes(item.id)},on:{"click":function($event){return _vm.handleSendReminderInvite({inviteId: item.id})}}},[_vm._v("Send Reminder")]):_vm._e()]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleDeleteInvite({inviteId: item.id})}}},on),[_c('v-icon',{attrs:{"color":_vm.canDelete({inviteId: item.id}) ? 'error' : 'grey lighten-3',"small":""}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('div',{staticClass:"tooltip-text"},[(_vm.canDelete({inviteId: item.id}))?[_vm._v("Permanently delete this invitee?")]:[_c('p',{staticClass:"warning--text"},[_vm._v("This invitee has one or more connections.")]),_c('p',{staticClass:"warning--text"},[_vm._v("To delete invitee, first delete their Connection.")])]],2)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }