<template lang="pug">
    .invitation-code-input
      v-text-field(outlined dense :loading="loading" :disabled="loading || invitationCodeIsReadOnly" v-model="invitationCode", label="Invitation Code" persistent :messages="outputMsg" placeholder="#ABC$123!&" :rules="[rules.required, rules.manualEntry]" @input="validateInvitationCodeIfReady" )
</template>

<script>
import { call, sync } from "vuex-pathify";

export default {
  name: "InvitationCodeInput",
  props: { readOnly: { type: Boolean, default: false } },
  data() {
    return {
      loading: false,
      rules: {
        required: value =>
          !!value ||
          "Please enter your Invitation Code, or just click the link in your invite email.",
        manualEntry: value =>
          (value && value.toString().length == 10) || "Invalid format."
      },
      outputMsg: ""
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...sync({
      invitationCode: "Invitations/registration@invitationCode",
      invitationCodeValidated:
        "Invitations/registration@invitationCodeValidated"
    }),
    invitationCodeIsReadOnly() {
      return (
        Boolean(this.$route.query.invitationCode) &&
        this.$route.query.invitationCode.length == 10
      );
    },
    invitationCodeIsValidatable() {
      return Boolean(this.invitationCode) && this.invitationCode.length == 10;
    }
  },
  methods: {
    ...call({
      validateInvitationCode: "Invitations/validateInvitationCode"
    }),
    async validateInvitationCodeIfReady() {
      if (!this.loading && this.invitationCodeIsValidatable) {
        this.loading = true;
        const inviteData = await this.validateInvitationCode();
        if (!inviteData) {
          this.outputMsg = "CODE INVALID OR EXPIRED";
          this.invitationCodeValidated = false;
          this.invitationHasExpired = true;
          this.$emit("expired", true);
        } else {
          this.outputMsg = "CODE CONFIRMED";
          this.invitationHasExpired = false;
          this.invitationCodeValidated = true;
          this.$emit("validated", inviteData);
        }
        this.loading = false;
      }
    },
    init() {
      this.invitationCode =
        this.$route.query && this.$route.query.invitationCode
          ? this.$route.query.invitationCode
          : "";
      this.validateInvitationCodeIfReady();
    }
  }
};
</script>
