<template lang="pug">
     .modal-update-password
        v-dialog(v-model="showModalEnterPassword" max-width="400px")
            v-card.py-6.px-12
                h2.mb-8 Enter current password
                p To verify you'd like to make this change, please enter your current password first
                v-text-field(type="password" v-model="password" hide-details label="Current Password", placeholder="•••••••••••", outlined background-color="grey lighten-4")
                .error--text(v-if="error").mt-4 {{error}}
                .actions.d-flex.flex-column.justify-center.align-center.mt-4
                    v-btn(depressed color="accent" @click="handleContinue").mb-2 Continue
                    v-btn(depressed color="error" text @click="showModalEnterPassword = false") Cancel
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  name: "UpdatePassword",
  props: {
    error: { type: String, default: null }
  },
  data() {
    return {
      password: null
    };
  },
  computed: {
    ...sync({
      showModalEnterPassword: "Nav/modals@showModalEnterPassword"
    })
  },
  created() {
    this.init();
  },
  methods: {
    handleContinue() {
      this.$emit("click", this.password);
    },
    init() {
      this.password = null;
    }
  }
};
</script>
