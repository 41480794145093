import { make } from "vuex-pathify";
import store from "@/store/index";

import ManageSystems from "@/components/ZWAdmin/ManageSystems.vue";

import Home from "@/components/Dashboard/Sections/Home/Home.vue";
import InviteList from "@/components/Dashboard/Sections/Invites/InviteList.vue";
import ConnectionList from "@/components/Dashboard/Sections/Connections/ConnectionList.vue";
import ManageUsers from "@/components/Dashboard/Sections/Users/ManageUsers.vue";
import ManageBilling from "@/components/Dashboard/Sections/Billing/ManageBilling.vue";
import Profile from "@/components/Dashboard/Sections/Profile/Profile.vue";

import ConnectButtons from "@/components/Dashboard/Sections/Connections/ConnectButtons.vue";
import UnitCount from "@/components/Dashboard/Sections/Connections/UnitCount.vue";
import UnitNames from "@/components/Dashboard/Sections/Connections/UnitNames.vue";
import FinishedConnecting from "@/components/Dashboard/Sections/Connections/FinishedConnecting.vue";

const state = {
  showNav: true,
  miniNav: false,
  modals: {
    showModalConnectNewDataSource: false,
    showModalDeleteConnection: false,
    showModalEditAccountDetails: false,
    showModalEnterPassword: false,
    showModalEditPassword: false,
    components: [ConnectButtons, UnitCount, UnitNames, FinishedConnecting]
  },
  activeDashboardComponent: null,
  activeConnectNewDataSourceComponent: null
};
const getters = {
  ...make.getters(state)
};

const mutations = make.mutations(state);

const actions = {
  showConnectNewDataSourceModal: ({ commit, dispatch }, payload) => {
    store.set("Nav/modals@showModalConnectNewDataSource", payload);
    if (!payload) {
      commit("SET_ACTIVE_CONNECT_NEW_DATA_SOURCE_COMPONENT", null);

      dispatch("Connections/getConnections", {}, { root: true });
    }
  },
  setActiveDashboardComponent: ({ commit }, { page }) => {
    if (page.toLowerCase() === "home") {
      commit("SET_ACTIVE_DASHBOARD_COMPONENT", Home);
    } else if (page.toLowerCase() === "invitelist") {
      commit("SET_ACTIVE_DASHBOARD_COMPONENT", InviteList);
    } else if (page.toLowerCase() === "profile") {
      commit("SET_ACTIVE_DASHBOARD_COMPONENT", Profile);
    } else if (page.toLowerCase() === "connectionlist") {
      commit("SET_ACTIVE_DASHBOARD_COMPONENT", ConnectionList);
    } else if (page.toLowerCase() === "billing") {
      commit("SET_ACTIVE_DASHBOARD_COMPONENT", ManageBilling);
    } else if (page.toLowerCase() === "users") {
      commit("SET_ACTIVE_DASHBOARD_COMPONENT", ManageUsers);
    } else if (page.toLowerCase() === "systems") {
      commit("SET_ACTIVE_DASHBOARD_COMPONENT", ManageSystems);
    } else {
      commit("SET_ACTIVE_DASHBOARD_COMPONENT", {});
    }
  },
  setActiveConnectNewDataSourceComponent: ({ commit, getters }, to) => {
    console.log("Setting active dashboard component...", to);
    if (to) {
      if (to.toUpperCase() === "EXIT") {
        this.actions.showConnectNewDataSourceModal(false);
        return;
      }
      const component = getters.modals.components.find(
        component => component.name === to
      );
      if (component) {
        console.log("found that component");
        commit("SET_ACTIVE_CONNECT_NEW_DATA_SOURCE_COMPONENT", component);
      } else {
        console.log("failed to find that component");
        commit("SET_ACTIVE_CONNECT_NEW_DATA_SOURCE_COMPONENT", {});
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
