<template lang="pug">
    .manage-systems
        DashboardSectionWrapper(:subtitle="$options.subtitle" :loading="loading")
        SystemsTable(@change:loading="loading = $event")

</template>

<script>
import DashboardSectionWrapper from "@/components/Dashboard/Sections/DashboardSectionWrapper.vue";
import SystemsTable from "@/components/ZWAdmin/SystemsTable.vue";
export default {
  name: "ManageSystems",
  title: "Manage Systems",
  components: {
    DashboardSectionWrapper,
    SystemsTable
  },
  data() {
    return { loading: true };
  }
};
</script>
