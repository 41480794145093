import Vue from "vue";
import Vuex from "vuex";
import pathify from "vuex-pathify";
import { make } from "vuex-pathify";
Vue.use(Vuex);

import Billing from "./modules/Billing";
import Connections from "./modules/Connections";
import Invitations from "./modules/Invitations";
import Nav from "./modules/Nav";
import Onboarding from "./modules/Onboarding";
import Reports from "./modules/Reports";
import System from "./modules/System";
import User from "./modules/User";
import Window from "./modules/Window";

const state = {
  hostname:
    window.location.hostname.split(".").length == 3
      ? window.location.hostname
          .split(".")
          .splice(1, 3)
          .join(".")
      : window.location.hostname,
  host: window.location.host
};

export default new Vuex.Store({
  namespaced: true,
  plugins: [pathify.plugin], // activate plugin
  state,
  getters: {
    prettyPrintDate: () => dt => {
      if (dt) {
        const options = { month: "numeric", day: "numeric", year: "numeric" };
        const americanDate = new Intl.DateTimeFormat("en-US", options).format(
          dt
        );
        return americanDate;
      } else return null;
    },
    prettyPrintTimestamp: () => ts => {
      if (ts && ts._seconds && ts._nanoseconds) {
        const dt = new Date(
          (ts._seconds + ts._nanoseconds * 0.00000001) * 1000
        );
        const options = { month: "numeric", day: "numeric", year: "numeric" };
        const americanDate = new Intl.DateTimeFormat("en-US", options).format(
          dt
        );
        return americanDate;
      } else return null;
    },
    isDev: () => window && !!window.webpackHotUpdate,
    originApi: (state, getters) => {
      const { isDev } = getters;
      return isDev ? "http://localhost:5001/zeewise-mvp/us-central1" : "/api";
    },
    originWww: (state, getters) => {
      const { host } = getters;
      return host;
    },
    ...make.getters(state)
  },
  mutations: { ...make.mutations(state) },
  actions: {},
  modules: {
    Onboarding,
    Connections,
    User,
    System,
    Nav,
    Invitations,
    Window,
    Reports,
    Billing
  }
});
