<template lang="pug">
    .users-table(v-show="!loading")
        
        .no-users(v-if="!users || users.length < 1")
          v-card(color="grey lighten-4" flat).pa-5.ma-5.text-left
            p You do not have permission to view any users
        
        .user-data(v-else)
          h2.mt-10.mb-4 Brand Space Admins
          .users-admins
            v-data-table.rounded(:headers="headers", :items="admins", :hide-default-footer="admins.length < 9")
            .d-flex
              v-btn.mr-6.align-self-end(outlined color="accent" :disabled="disableAddAdmin") Add Brand Space Admin
              div.align-self-end 
                a.error--text.hoverable(href="/dashboard/billing") Not Available on Your Existing Plan

          h2.mt-10.mb-4 Unit Owners
          .users-owners
            v-data-table.rounded(:headers="headers", :items="invitees", :hide-default-footer="invitees.length < 9")
              template(v-slot:no-data) No opened invites
            v-btn(outlined color="accent" to="/dashboard/inviteList") Add Owner
            
</template>

<script>
import { get, call } from "vuex-pathify";
export default {
  name: "UsersTable",
  data() {
    return {
      loading: false,
      disableAddAdmin: true,
      disableEnableInvitee: true,
      headers: [
        {
          text: "First Name",
          value: "firstName",
          align: "left"
        },
        {
          text: "Last Name",
          value: "lastName",
          align: "center"
        },
        {
          text: "Email",
          value: "email",
          align: "left"
        },
        {
          text: "Created",
          value: "createdAt",
          align: "center"
        }
      ]
    };
  },
  watch: {
    loading(newVal) {
      this.$emit("change:loading", newVal);
    }
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      users: "User/viewableUsers",
      prettyPrintTimestamp: "prettyPrintTimestamp"
    }),
    admins() {
      return this.users
        .map(user => {
          return {
            ...user,
            createdAt: this.prettyPrintTimestamp(user.createdAt)
          };
        })
        .filter(user => user.role.id === 0);
    },
    invitees() {
      return this.users
        .map(user => {
          return {
            ...user,
            createdAt: this.prettyPrintTimestamp(user.createdAt)
          };
        })
        .filter(user => user.role.id === 1);
    }
  },
  methods: {
    ...call({
      getUsers: "User/getViewableUsers"
    }),
    async init() {
      this.loading = true;
      // set header colors to standard header color
      this.headers.forEach(header => (header.class = "sectionHeaderColor"));
      await this.getUsers();
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
::v-deep .v-data-table-header {
  th span {
    color: white;
    font-size: 1rem;
    font-weight: 400;
  }
}
</style>
