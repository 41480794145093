<template lang="pug">
  .work-email-address-input
    v-text-field(outlined dense v-model="value", placeholder="Enter Email for Invitee" :label="label", hide-details, @change="handleChange")
</template>

<script>
import { sync, call } from "vuex-pathify";

export default {
  name: "InviteeEmailAddress",
  props: {
    label: { type: String, default: "Invitee Email Address" }
  },
  watch: {},
  data() {
    return { value: "" };
  },
  created() {},
  computed: {
    ...sync({
      inviteEmailAddresses: "Onboarding/registration@inviteEmailAddresses"
    })
  },
  methods: {
    ...call({}),
    handleChange(e) {
      const alreadyAdded = this.inviteEmailAddresses.find(
        itm => itm && itm.toLowerCase() === e
      );

      if (!alreadyAdded) {
        this.$set(this.inviteEmailAddresses, this.$vnode.key, e);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
