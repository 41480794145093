import { make } from "vuex-pathify";
import Firebase from "firebase/app";
const sumBy = require("lodash.sumby");

const state = {
  plan: null,
  stripe: {
    publishableKey:
      window && !!window.webpackHotUpdate
        ? "pk_test_51Ha6GmB18mqHaNdMAqppL5CGCCNSBw07x8Gy2EoqI8ItPZXL5s9Zb8urjgM4CT7uqrfD7UQjbkYg6rf5c0okqRoI00ojiPLvUg"
        : "pk_live_51Ha6GmB18mqHaNdMYKVKbVPf6FxhpZvynG1hxWCZvs30R3KqALiIRFrWZkR8QKjlTmJzjB0wOgpRoCxd7CnDZv0o00rk7rTzk3"
  }
};
const getters = {
  ...make.getters(state)
};

const mutations = make.mutations(state);

const actions = {
  setBillingPlan: ({ commit }, { plan }) => {
    commit("SET_PLAN", plan);
  },

  getStripeCheckoutRedirectLink: async ({ getters, rootGetters, dispatch }) => {
    const { email, conceptId, stripeCustomerId } = rootGetters["User/userData"];
    let connections = rootGetters["Connections/connections"];
    if (!connections || connections.length == 0)
      connections = await dispatch(
        "Connections/getConnections",
        {},
        { root: true }
      );

    const unitCount =
      !connections || connections.length === 0
        ? 0
        : sumBy(connections, conn => conn.unitCount);

    const getStripeCheckoutRedirectLink = Firebase.functions().httpsCallable(
      "getStripeCheckoutRedirectLink"
    );

    const successUrl = "/dashboard/billing?paid=true&status=success";
    const cancelUrl = "/dashboard/billing?paid=false?status=cancel";

    const checkoutLinkRes = await getStripeCheckoutRedirectLink({
      email,
      billingPlan: getters.plan,
      stripeCustomerId,
      userId: Firebase.auth().currentUser.uid,
      conceptId,
      successUrl,
      cancelUrl,
      coupon: null,
      unitCount
    });

    return checkoutLinkRes && checkoutLinkRes.data && checkoutLinkRes.data.link
      ? checkoutLinkRes.data.link
      : null;
  },
  openCheckout: ({ getters }, checkoutId) => {
    if (checkoutId) {
      const stripe = window.Stripe(getters.stripe.publishableKey);
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: checkoutId
        })
        .then(function(result) {
          window.debug("redirect result", result);
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
    } else {
      console.error("Invalid Stripe Checkout ID, so impossible to redirect.");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
