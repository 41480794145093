<template lang="pug">
    .connect-to-source-button(v-if="text").text-center
        .coming-soon(v-if="disabled").grey--text Coming soon!
        v-btn(outlined :color="color", :disabled="disabled" large min-width="300px" @click="$emit('click')")
          v-img(:src="logo" width="32px" max-width="32px").mr-2
          .font-weight-bold {{text}}
</template>

<script>
const VALID_TYPES = ["QUICKBOOKS", "XERO", "FRESHBOOKS"];

export default {
  name: "ConnectToSourceButton",
  props: {
    type: { type: String, required: true },
    disabled: { type: Boolean, default: false }
  },
  data() {
    const logos = {};

    VALID_TYPES.map(type => {
      const key = type.toLowerCase();
      const val = require(`@/assets/third-party/icons/${type.toLowerCase()}.svg`);
      logos[key] = val;
    });

    return {
      logos
    };
  },
  computed: {
    textIsValid() {
      return VALID_TYPES.includes(this.text);
    },
    logo() {
      return this.logos[this.type.toLowerCase()];
    },
    color() {
      if (this.type === "QUICKBOOKS") return "#2CA01C";
      else if (this.type === "XERO") return "#2BAAED";
      else if (this.type === "FRESHBOOKS") return "#3273D5";
      else return null;
    },
    text() {
      const base = "Connect to ";
      if (this.type === "QUICKBOOKS") return base + "QuickBooks";
      else if (this.type === "XERO") return base + "Xero";
      else if (this.type === "FRESHBOOKS") return base + "FreshBooks";
      else return null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
