<template lang="pug">
    .confirm-delete-modal
        v-dialog(v-model="showModalDeleteConnection" max-width="500px")
          v-card.py-6.px-12
            h2.text-center Are you sure?
            p Removing this Connection will delete all dashboard data and cannot be undone.
            v-btn(outlined block @click="handleCancel").mb-3 Cancel
            v-btn(block depressed color="red" @click="handleRemove").white--text Remove this Connection
</template>

<script>
import { sync, call } from "vuex-pathify";
export default {
  name: "ModalConfirmDelete",
  props: {
    connectionIdToDelete: { type: String, required: true }
  },
  computed: {
    ...sync({
      showModalDeleteConnection: "Nav/modals@showModalDeleteConnection"
    })
  },
  methods: {
    ...call({
      getConnections: "Connections/getConnections",
      deleteConnection: "Connections/deleteConnection"
    }),
    handleCancel() {
      this.$emit("done");
    },
    async handleRemove() {
      this.$emit("pseudoDelete");
      this.showModalDeleteConnection = false;
      await this.deleteConnection({ connectionId: this.connectionIdToDelete });
      //await this.ungetConnections();
      await this.getConnections();
      this.$emit("done");
    }
  }
};
</script>
