<template lang="pug">
    .system-report-averages-line-items
        v-row.item
          v-col(cols="7")
            .label.primary--text Average Revenue
          v-col(cols="4").text-right
            .val.textDark--text.border-bottom.d-inline-block {{averageRevenue.value | displayValue(numDecimals, averages.currency)}}
        
        v-row.item(v-for="itm in items", :key="itm.key")
          v-col(cols="1")
            v-icon.indicator(:color="itm.color") mdi-circle-medium
          v-col(cols="6")
            .label.textDark--text {{itm.label}}
          v-col(cols="4").text-right
            .val.textDark--text {{itm.value | displayValue(numDecimals, averages.currency)}}
</template>

<script>
import { get } from "vuex-pathify";
import { floatToCurrency } from "@/helpers";
export default {
  name: "SystemAverageLineItems",
  data() {
    return { numDecimals: 0 };
  },
  filters: {
    displayValue: (val, numDec, currency) =>
      floatToCurrency(val, numDec, currency)
  },
  props: {
    averageRevenue: { type: Object, required: true },
    averages: { type: Object, required: true }
  },
  computed: {
    ...get({ systemReportFields: "Reports/systemReportFields" }),
    items() {
      return this.systemReportFields.map(field => {
        return {
          ...field,
          value: this.averages.asFloat[field.key]
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

.label {
  font-size: 0.875rem;
}

.val {
  font-size: 0.875rem;
}

.border-bottom {
  border-bottom: 2px solid $textDark;
}
</style>
