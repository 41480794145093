export const isValidDomain = str => {
  const re = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/;

  return re.test(String(str).toLowerCase());
};

export const isValidEmail = str => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(str).toLowerCase());
};

export const isValidPassword = str => {
  const re = /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  return re.test(String(str));
};

export const isValidBrandSpaceName = str => {
  return str.indexOf("  ") >= 0
    ? "Only single spaces allowed"
    : str.length < 3
    ? "Minimum length of 3"
    : str.length > 60
    ? "Maximum length of 60"
    : !isOnlyAlphaNumericOrAddl(str, [32, 45])
    ? "Only letters, numbers, spaces and dashes are supported"
    : true;
};

export const isValidBrandSpaceSubdomain = str => {
  return str.indexOf(" ") >= 0
    ? "No spaces allowed"
    : str.length < 3
    ? "Minimum length of 3"
    : str.length > 20
    ? "Maximum length of 20"
    : !isOnlyAlphaNumericOrAddl(str, [45])
    ? "Only letters, numbers and dashes are supported"
    : true;
};

export const isOnlyAlphaNumeric = str => {
  /* faster than regex: https://stackoverflow.com/questions/4434076/best-way-to-alphanumeric-check-in-javascript */
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123) // upper alpha (A-Z)
    ) {
      return false;
    }
  }
  return true;
};

export const isOnlyAlphaNumericOrAddl = (str, arrAddl) => {
  /* faster than regex: https://stackoverflow.com/questions/4434076/best-way-to-alphanumeric-check-in-javascript */
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123) && // lower alpha (A-Z)
      !arrAddl.includes(code)
    ) {
      return false;
    }
  }
  return true;
};

export const splitEmail = email => {
  if (!email || !email.includes("@")) return null;
  return {
    username: email.split("@")[0],
    domain: email.split("@")[1]
  };
};

export const sentenceCase = str => {
  if (!str || str.length < 1) return "";

  return str[0].toUpperCase() + str.substr(1, str.length - 1);
};

export const titleCase = str => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const forceFirstCapital = str => {
  if (!str || str.length < 1) return "";
  return str[0].toUpperCase() + str.substr(1, str.length - 1).toLowerCase();
};

export const lastDayOfDateMonth = (year, month) => {
  if (month < 0 || month > 11)
    throw new Error("Only js months (0-11) are supported");
  return new Date(year, month + 1, 0).getDate();
};

export const lastDayOfCalendarMonth = (year, month) => {
  if (month < 1 || month > 12)
    throw new Error("Only calendar months (1-12) are supported");
  return new Date(year, month + 1, 0).getDate();
};

export const stringCurrencyToInt = str => {
  if (!str || str.length < 1) return str;

  const float = parseFloat(str);
  return isNaN(float) ? "" : float * 100;
};

export const intToCurrency = (int, showCurrencySymbol = true) => {
  if (int === null || typeof int === "undefined" || isNaN(parseInt(int)))
    return null;

  const val = int / 100;
  const res = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: Math.trunc(Math.abs(val)).toFixed().length
  }).format(val);

  return showCurrencySymbol ? res : res.split("$").join("");
};

export const floatToCurrency = (
  flt,
  numDecimals = 2,
  currency = "USD",
  showCurrencySymbol = true
) => {
  if (flt === null || typeof flt === "undefined" || isNaN(parseFloat(flt)))
    return null;

  const res = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals
  }).format(flt);

  return showCurrencySymbol ? res : res.split("$").join("");
};

export const stringToCurrency = (
  str,
  withSymbol = false,
  noDecimals = false
) => {
  const lang = "en-US";
  const opt = {
    style: "currency",
    currency: "USD"
  };

  if (noDecimals) {
    opt.maximumFractionDigits = 0;
    opt.minimumFractionDigits = 0;
  }

  let result = Intl.NumberFormat(lang, opt).format(str);
  return withSymbol ? result : result.split("$").join("");
};

export const ensureInteger = (int, failoverIntValue) => {
  if (int === null || typeof int === "undefined" || isNaN(parseInt(int)))
    return failoverIntValue;
  else return parseInt(int);
};

export const ensureFloat = (flt, failoverFloatValue) => {
  if (flt === null || typeof flt === "undefined" || isNaN(parseFloat(flt)))
    return failoverFloatValue;
  else return parseFloat(flt);
};

export const roundFloat = (flt, numberDecimals) => {
  return Number(flt.toFixed(numberDecimals));
};

export const zeroDivideInt = (numerator, denominator, failoverIntValue) => {
  if (denominator === null || typeof denominator === "undefined")
    return failoverIntValue;
  const intDenominator = parseInt(denominator);
  if (isNaN(intDenominator) || intDenominator === 0) return failoverIntValue;
  else return parseInt(numerator) / intDenominator;
};

export const zeroDivideFloat = (numerator, denominator, failoverFloatValue) => {
  if (denominator === null || typeof denominator === "undefined")
    return failoverFloatValue;
  const floatDenominator = parseFloat(denominator);
  if (isNaN(floatDenominator) || floatDenominator === 0)
    return failoverFloatValue;
  else return parseFloat(numerator) / floatDenominator;
};

export const copyToClipboard = str => {
  /* ——— Derived from: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
         improved to add iOS device compatibility——— */
  const el = document.createElement("textarea"); // Create a <textarea> element

  const storeContentEditable = el.contentEditable;
  const storeReadOnly = el.readOnly;

  el.value = str; // Set its value to the string that you want copied
  el.contentEditable = true;
  el.readOnly = false;
  el.setAttribute("readonly", false); // Make it readonly false for iOS compatability
  el.setAttribute("contenteditable", true); // Make it editable for iOS
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  el.setSelectionRange(0, 999999);
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }

  el.contentEditable = storeContentEditable;
  el.readOnly = storeReadOnly;
};

export const waitForElemToExist = selector => {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
};

export const setCookie = (cname, cvalue, exdays, domain) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  const domainString = domain ? "domain=" + domain + ";" : "";
  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";path=/" + domainString;
};

export const truncateStringBeyond = (str, maxChars) => {
  return str.length <= maxChars ? str : str.substr(0, maxChars) + "...";
};

export const randomChars = length => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  let result = "";
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const yyyyMmDd = dt => {
  try {
    if (!(dt instanceof Date) || isNaN(dt))
      throw new Error("Could not convert invalid js date to QBO date");

    const year = dt.getFullYear();
    const month = dt.getMonth() + 1;
    const day = dt.getDate();

    const paddedDay = day < 10 ? "0" + day : day;
    const paddedMonth = month < 10 ? "0" + month : month;

    const sep = "";
    return year + sep + paddedMonth + sep + paddedDay;
  } catch (err) {
    console.log(err);
    return dt;
  }
};
