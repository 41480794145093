<template lang="pug">
    .invite-stats
      v-row(justify="space-between" align="center")
        v-col(cols="12", md="4", v-for="section in sections", :key="section.title")
          InviteStatCard(:title="section.title", :value="section.value", :description="section.description")
</template>

<script>
import { get } from "vuex-pathify";
import InviteStatCard from "@/components/Dashboard/Sections/Invites/InviteStatCard.vue";
export default {
  name: "InviteStats",
  components: { InviteStatCard },
  data() {
    return {};
  },
  computed: {
    ...get({ invites: "Invitations/invites" }),
    completedConnections() {
      return this.invites.filter(invite => invite.inviteeCompletedOnboarding)
        .length;
    },
    openedInvites() {
      return this.invites.filter(invite => invite.inviteeClickedToOpenEmail)
        .length;
    },
    sections() {
      return [
        { title: "Invites Sent", value: this.invites.length },
        {
          title: "Invites Opened",
          value: this.openedInvites,
          description:
            this.openedInvites > 0
              ? this.percentOfTotal(this.openedInvites) + "% of total invites"
              : undefined
        },
        {
          title: "Invites Completed",
          value: this.completedConnections,
          description:
            this.completedConnections > 0
              ? this.percentOfTotal(this.completedConnections) +
                "% of total invites"
              : undefined
        }
      ];
    }
  },
  methods: {
    percentOfTotal(val) {
      return parseInt((val / this.invites.length) * 100);
    }
  }
};
</script>
