<template lang="pug">
    .create-system
      OnboardingWrapper(:title="header.title", :subTitle="header.subTitle", :description="header.description", :stage="header.stage")
        FinishedConnecting(type="ONBOARDING" @click:finish="handleClickFinish")
</template>

<script>
import { sync, call } from "vuex-pathify";
import OnboardingWrapper from "@/components/Onboarding/OnboardingWrapper.vue";
import FinishedConnecting from "@/components/Dashboard/Sections/Connections/FinishedConnecting.vue";
export default {
  name: "ConnectionComplete",
  components: { OnboardingWrapper, FinishedConnecting },
  data() {
    return {
      header: {
        title: "Connection Complete",
        subTitle: " ",
        description: "",
        stage: 0
      },
      loading: false,
      errors: {}
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...sync({
      inviteeCompletedOnboarding:
        "Invitations/events@inviteeCompletedOnboarding"
    })
  },
  methods: {
    ...call({
      setActiveItem: "Onboarding/setActiveItem",
      updateInviteDetails: "Invitations/updateInviteDetails"
    }),
    async handleConnectAnotherFile() {
      this.setActiveItem({ to: "ConnectUnit" });
    },
    async handleClickFinish() {
      this.inviteeCompletedOnboarding = true;
      await this.updateInviteDetails();
    },
    init() {
      this.inviteeCompletedOnboarding = true;
    }
  }
};
</script>
