<template lang="pug">
    .modal-connect-data-source
        v-dialog(:value="showModalConnectNewDataSource" persistent max-width="500px")
          v-card.py-8.px-12
              v-overlay(v-model="connecting")
              h2.text-center.mb-8 Add Connection
              //- the below component may be the connect buttons, or it may be other content, such as the "Unit Count Modal"
              component(v-if="activeConnectNewDataSourceComponent && activeConnectNewDataSourceComponent.name", :is="activeConnectNewDataSourceComponent", :key="activeConnectNewDataSourceComponent.name", @nav="handleNav", v-bind="props", @connecting="connecting = $event")

              p.text-center.pa-6(v-show="allowCancel")
                a.smaller.nav-link( @click="showConnectNewDataSourceModal(false);") cancel and add later
              p.text-center.pa-6(v-show="allowFinish")
                a.smaller.nav-link(@click="showConnectNewDataSourceModal(false);") I'm done
</template>

<script>
import { get, call } from "vuex-pathify";

export default {
  name: "ModalConnectNewDataSource",
  watch: {
    showModalConnectNewDataSource(newVal) {
      if (newVal) this.init();
    }
  },
  data() {
    return { type: "DASHBOARD", props: {}, connecting: false };
  },
  computed: {
    ...get({
      activeConnectNewDataSourceComponent:
        "Nav/activeConnectNewDataSourceComponent",
      showModalConnectNewDataSource: "Nav/modals@showModalConnectNewDataSource"
    }),
    allowCancel() {
      return (
        this.activeConnectNewDataSourceComponent &&
        this.activeConnectNewDataSourceComponent.name === "ConnectButtons" &&
        this.type === "DASHBOARD"
      );
    },
    allowFinish() {
      return (
        this.activeConnectNewDataSourceComponent &&
        this.activeConnectNewDataSourceComponent.name ===
          "FinishedConnecting" &&
        this.type === "DASHBOARD"
      );
    }
  },
  methods: {
    ...call({
      showConnectNewDataSourceModal: "Nav/showConnectNewDataSourceModal",
      setActiveConnectNewDataSourceComponent:
        "Nav/setActiveConnectNewDataSourceComponent"
    }),
    async init() {
      if (!this.activeConnectNewDataSourceComponent) {
        await this.setActiveConnectNewDataSourceComponent("ConnectButtons");
      }
    },
    handleNav(evt) {
      this.props = { ...evt.data, type: "DASHBOARD" };
      this.setActiveConnectNewDataSourceComponent(evt.to);
    }
  }
};
</script>
