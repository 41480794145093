<template lang="pug">
.modal-edit-account-details
    v-dialog(v-model="showModalEditAccountDetails" max-width="600px")
        v-card.py-6.px-12
            h2.text-center.mb-8 Account Details

            BasicDetails(:readOnly="false" :showPseudoPassword="false" :error="errorMessage", @change="basicDetails = $event")

            .actions.mt-5.d-flex.flex-column
                v-btn(block color="accent" depressed large @click="handleSave").mb-2 Save Changes
                v-btn(block color="error" outlined large @click="showModalEditAccountDetails = false") Cancel

</template>

<script>
import { sync } from "vuex-pathify";
import BasicDetails from "@/components/Dashboard/Sections/Profile/BasicDetails.vue";
import { isValidEmail } from "@/helpers";

export default {
  name: "ModalEditAccountDetails",
  components: { BasicDetails },
  data() {
    return {
      basicDetails: {},
      errorMessage: null
    };
  },
  computed: {
    ...sync({
      showModalEditAccountDetails:
        "Nav/modals@showModalEditAccountDetails"
    })
  },
  methods: {
    handleSave() {
      this.errorMessage = null;
      if (Object.entries(this.basicDetails).length < 1) {
        this.showModalEditAccountDetails = false;
      } else {
        if (
          this.basicDetails &&
          this.basicDetails.email &&
          isValidEmail(this.basicDetails.email)
        ) {
          this.$emit("click:save", this.basicDetails);
        } else {
          this.errorMessage = "You cannot save an invalid email address.";
        }
      }
    }
  }
};
</script>
