<template lang="pug">
     .modal-update-password
        v-dialog(v-model="showModalEditPassword" max-width="400px")
            v-card.py-6.px-12
                h2.mb-8.text-center Change Password
                v-text-field(dense type="password" v-model="oldPassword" hide-details label="Current Password", placeholder="•••••••••••", outlined background-color="grey lighten-4").mb-4
                v-text-field(dense type="password" v-model="newPassword" hide-details label="New Password", placeholder="•••••••••••", outlined background-color="grey lighten-4").mb-4
                v-text-field(dense type="password" v-model="confirmNewPassword" hide-details label="Confirm New Password", placeholder="•••••••••••", outlined background-color="grey lighten-4")
                
                .error--text(v-if="error").my-4 {{error}}
                .actions.d-flex.flex-column.justify-center.align-center.mt-5
                    v-btn(depressed color="accent" @click="handleSave").mb-2 Save Changes
                    v-btn(depressed color="error" text @click="showModalEditPassword = false") Cancel
</template>

<script>
import { sync, call } from "vuex-pathify";
import { isValidPassword } from "@/helpers";
export default {
  name: "ChangePassword",
  data() {
    return {
      error: null,
      oldPassword: null,
      newPassword: null,
      confirmNewPassword: null
    };
  },
  computed: {
    ...sync({
      showModalEditPassword: "Nav/modals@showModalEditPassword"
    })
  },
  created() {
    this.init();
  },
  methods: {
    ...call({ changePassword: "User/changePassword" }),
    async handleSave() {
      this.error = null;
      if (!this.oldPassword || !this.newPassword || !this.confirmNewPassword) {
        this.error = "You must fill in all 3 boxes";
      } else if (!isValidPassword(this.newPassword)) {
        this.error =
          "Your new password does not match our password requirements";
      } else if (this.newPassword !== this.confirmNewPassword) {
        this.error =
          "It looks like you mistyped your new password in one or both boxes";
      } else if (this.newPassword === this.oldPassword) {
        this.error =
          "You can't set your new password to be exactly the same as your old password";
      } else {
        const res = await this.changePassword({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        });
        if (res === true) {
          this.showModalEditPassword = false;
        } else {
          this.error = "There was an error updating to your new password";
        }
      }
    },
    init() {
      this.oldPassword = null;
      this.newPassword = null;
    }
  }
};
</script>
