<template lang="pug">
    .name-input
      v-form(ref="form")
        v-row
          v-col(cols="6")
            v-text-field(dense :disabled="disabled" v-model="firstName", outlined placeholder="Mary" label="First Name", :rules="[rules.required]", hide-details)
          v-col(cols="6")
            v-text-field(dense :disabled="disabled" v-model="lastName", outlined placeholder="Smith" label="Last Name", :rules="[rules.required]", hide-details)
</template>

<script>
import { sync } from "vuex-pathify";

export default {
  name: "NameInput",
  props: {
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      rules: {
        required: value => !!value || "Required."
      }
    };
  },
  computed: {
    ...sync({
      firstName: "Onboarding/registration@firstName",
      lastName: "Onboarding/registration@lastName"
    })
  }
};
</script>
